// https://nuxt.com/docs/examples/advanced/use-custom-fetch-composable
import type { UseFetchOptions } from "#app";
import type { NitroFetchRequest } from "nitropack";
import { getCorrelationHeaders } from "~/utils/cookies/corrrelation-headers";

type FetchWrapper = ReturnType<typeof useFetch>;

async function externalFetch<T>(
  request: NitroFetchRequest,
  fetchOptions: UseFetchOptions<T> = {},
): Promise<FetchWrapper> {
  const options = {
    ...fetchOptions,
  };

  return useFetch(request, options);
}

export type ExternalFetch = typeof externalFetch;

async function internalFetch<T>(
  request: NitroFetchRequest,
  fetchOptions: UseFetchOptions<T> = {},
): Promise<FetchWrapper> {
  const options = {
    headers: {
      ...getCorrelationHeaders(),
    },
    ...fetchOptions,
  };

  return useFetch(request, options);
}

export type InternalFetch = typeof internalFetch;

export default defineNuxtPlugin(() => {
  return {
    provide: {
      externalFetch,
      internalFetch,
    },
  };
});
