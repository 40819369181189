import type { FactFinderProductHit } from "~/utils/factfinder/integration/factfinder.types";
import type { SparMdsaProduct } from "~/utils/mdsa/integration/mdsa.types";

export const getFallbackImage = () => "/images/product_fallback.jpg";

export const getFallbackImageAlt = (
  product: SparMdsaProduct | FactFinderProductHit["masterValues"],
) => {
  return [product.name1, product.name2, product.name3, product.name4]
    .filter((item) => item)
    .join(" ");
};
