<template>
  <div class="checkout-overview" role="region" aria-labelledby="checkoutOverviewHeading">
    <spar-heading
      v-if="headlineTitle"
      id="checkoutOverviewHeading"
      level="2"
      headline-style="2"
      :title="headlineTitle"
      additional-class="heading--left"
    />
    <div
      v-for="(entry, index) in entries"
      :key="index"
      class="checkout-overview__item"
      role="listitem"
      tabindex="0"
    >
      <span class="checkout-overview__item-label">{{ entry.label }}</span>
      <span
        :data-tosca="`checkout-overview-${entry.label.toLowerCase()}`"
        class="checkout-overview__item-value"
        >{{ entry.value }}</span
      >
    </div>
    <div
      v-if="totalPriceWithTax"
      class="checkout-overview__item checkout-overview__item--total-price-with-tax"
      data-tosca="checkout-total-price-with-tax"
      role="listitem"
      tabindex="0"
      :aria-label="`${$t('checkout.step.delivery.total.label')}:
        ${totalPriceWithTax.formattedValue}
        ${$t('global.taxes.vat.incl')}`"
    >
      <span class="checkout-overview__item-label">
        {{ $t("checkout.step.delivery.total.label") }}
      </span>
      <span class="checkout-overview__item-value" data-tosca="checkout-overview-total-price">
        {{ totalPriceWithTax.formattedValue }}
      </span>
      <span class="checkout-overview__item-sub">
        <button
          class="checkout-overview__info-button"
          :aria-label="$t('checkout.vatinfo.arialabel')"
          tosca-prefix="checkout-taxinfo-modal-trigger"
          @click="showTaxOverlay"
        >
          {{ $t("global.taxes.vat.incl") }}
        </button>
      </span>
    </div>

    <spar-overlay
      :is-active="isTaxOverlayOpen"
      :title="$t('checkout.vatinfo')"
      tosca-prefix="checkout-taxinfo"
      @close="hideTaxOverlay"
    >
      Tax overlay dummy content
    </spar-overlay>
  </div>
</template>

<script lang="ts" setup>
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import SparOverlay from "~/components/shared/SparOverlay/SparOverlay.vue";
import type { Price } from "~/types/occ.types";

const { $t } = useI18n();

const props = defineProps({
  headlineTitle: {
    type: String,
    default: "",
  },
  productDiscounts: {
    type: Object as PropType<Price>,
    default: undefined,
  },
  orderDiscounts: {
    type: Object as PropType<Price>,
    default: undefined,
  },
  deliveryCost: {
    type: Object as PropType<Price>,
    default: undefined,
  },
  subTotal: {
    type: Object as PropType<Price>,
    default: undefined,
  },
  totalPriceWithTax: {
    type: Object as PropType<Price>,
    default: undefined,
  },
});

/**
 * Builds list of all entries, which need to be displayed.
 */
const entries = computed(() => {
  const allEntries = [];

  if (props.productDiscounts?.value) {
    allEntries.push({
      tosca: "checkout-product-discounts",
      label: $t("checkout.overview.discounts"),
      value: `- ${props.productDiscounts.formattedValue}`,
    });
  }

  if (props.orderDiscounts?.value) {
    allEntries.push({
      tosca: "checkout-order-discounts",
      label: $t("checkout.coupon.code"),
      value: `- ${props.orderDiscounts.formattedValue}`,
    });
  }

  if (props.subTotal?.value) {
    allEntries.push({
      tosca: "checkout-subtotal",
      label: $t("checkout.overview.subtotal"),
      value: props.subTotal.formattedValue,
    });
  }

  if (props.deliveryCost?.value) {
    allEntries.push({
      tosca: "checkout-delivery-costs",
      label: $t("checkout.overview.deliverycosts"),
      value: props.deliveryCost.formattedValue,
    });
  }

  return allEntries;
});

const isTaxOverlayOpen = ref(false);

const showTaxOverlay = () => {
  isTaxOverlayOpen.value = true;
};

const hideTaxOverlay = () => {
  isTaxOverlayOpen.value = false;
};
</script>

<style lang="scss">
@use "./SparCheckoutOverview.scss";
</style>
