import type { NavigationNode } from "~/components/sections/SparHeader/SparMainNavigation/SparMainNavigation.types";
import type { IndexPage } from "~/plugins/indexes/indexes.types";

function resolveBreadcrumbIndex(breadcrumb: IndexPage, level: number): NavigationNode {
  return {
    level,
    categoryId: breadcrumb.uid,
    label: breadcrumb.index?.label ?? "",
    title: breadcrumb.index?.label ?? "",
    target: breadcrumb.index?.target ?? "",
    link: "url" in breadcrumb && breadcrumb.url ? breadcrumb.url : "",
    categoryIconUrl: breadcrumb.index?.icon,
    childCategories: [],
    levelPath: [],
    sections: [],
  };
}

function resolveBreadcrumbParents(
  index: IndexPage,
  indexes: IndexPage[],
  breadcrumbs: NavigationNode[] = [],
  level = 0,
): NavigationNode[] {
  ++level;

  const _index = indexes.find((breadcrumb) => breadcrumb.uid === index.uid);
  if (!_index) return [];

  const breadcrumb = resolveBreadcrumbIndex(_index, level);
  breadcrumbs.unshift(breadcrumb);

  if (index.index?.parents?.length === 0) {
    return breadcrumbs;
  } else {
    if (_index?.index?.parents?.[0]) {
      return resolveBreadcrumbParents(_index.index.parents[0], indexes, breadcrumbs);
    }

    return [];
  }
}

export function resolveBreadcrumbIndexes(indexes: IndexPage[]): Record<string, NavigationNode[]> {
  const breadcrumbs: Record<string, NavigationNode[]> = {};

  indexes.forEach((index) => {
    breadcrumbs[index.uid] = resolveBreadcrumbParents(index, indexes);
  });

  return breadcrumbs;
}
