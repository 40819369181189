import type { SparLightboxItem } from "~/components/shared/SparLightbox/SparLightbox.types";
import useI18n from "~/composables/i18n/useI18n";
import {
  productSchemaV1AssetsInnerTypeEnum,
  type ProductSchemaV1AssetsInner,
  type ProductSchemaV1AssetsInnerTypeEnum,
} from "~/types/mdsa.types";
import {
  getFallbackImage,
  getFallbackImageAlt,
} from "~/utils/contentstack/resolvers/product/product.utils";
import { ProductDocumentType, type SparMdsaProduct } from "~/utils/mdsa/integration/mdsa.types";

export function usePdpProduct(product: SparMdsaProduct | undefined) {
  const { $t } = useI18n();

  const assets = computed(() => product?.assets || []);

  const filterAssets = (type: ProductSchemaV1AssetsInnerTypeEnum[]) => {
    return assets.value.filter((asset) => (asset.type ? type.includes(asset.type) : false));
  };

  const productDocuments = computed(() =>
    filterAssets([productSchemaV1AssetsInnerTypeEnum.Document]),
  );
  const productImages = computed(() => filterAssets([productSchemaV1AssetsInnerTypeEnum.Image]));
  const productVideos = computed(() => filterAssets([productSchemaV1AssetsInnerTypeEnum.Video]));

  // position:pdf_datasheet -> pdf_datasheet
  const extractPictureType = (document: ProductSchemaV1AssetsInner) =>
    document.position?.replaceAll("position:", "");

  const filterProductDocuments = (documentType: ProductDocumentType) => {
    return productDocuments.value.filter((document) => {
      return extractPictureType(document) === documentType;
    });
  };

  // Get label for document type, based on JSON "position" property
  // position:pdf_datasheet -> pdf_datasheet -> pdp.product.documents.pdf_datasheet
  const getDocumentLabel = (document: ProductSchemaV1AssetsInner) => {
    const type = extractPictureType(document);
    if (!type) return "";
    return $t("pdp.product.documents." + type);
  };

  const galleryAssets = computed(() => {
    const res = [...productImages.value, ...productVideos.value]
      .map((item, index) => {
        const img =
          item.type === productSchemaV1AssetsInnerTypeEnum.Image ? item.servletBaseUrl : "";
        const src = img?.replace("{size}", "500").replace("{ext}", "jpg");
        const thumbnail = img?.replace("{size}", "105").replace("{ext}", "jpg");

        return {
          id: index,
          type: item.type,
          src,
          alt: item.altText || (product ? getFallbackImageAlt(product) : ""),
          thumbnail,
        };
      })
      .filter((asset) => asset.type && asset.src && asset.thumbnail) as SparLightboxItem[];

    // If there is no item in the array, add a generic placeholder image
    if (res.length === 0) {
      res.push({
        id: 0,
        type: productSchemaV1AssetsInnerTypeEnum.Image,
        src: getFallbackImage(),
        alt: $t("pdp.product.image.alt_fallback"),
        thumbnail: getFallbackImage(),
      });
    }

    return res;
  });

  return {
    filterProductDocuments,
    getDocumentLabel,
    productDocuments,
    productImages,
    productVideos,
    galleryAssets,
  };
}
