const sectionReferences: readonly string[] = Object.freeze([
  "sections.content",
  "sections_top.content",
  "sections_middle.content",
  "sections_bottom.content",
  "sections.content.slides",
  "sections.content.accordion.content",
  "sections.content.slider_items.content",
  "sections.content.teasers.content",
  "sections.content.fields.content", // FlexibleForm
  "sections.content.fields.fields.field", // FlexibleForm
  "sections.content.banner",
]);

// add references of subcomponents here as well
const subsectionReferences: readonly string[] = Object.freeze([
  "top_sections.content.accordion.content",
  "middle_sections.content.accordion.content",
  "bottom_sections.content.accordion.content",
  "top_sections.content.slider_items.content",
  "middle_sections.content.slider_items.content",
  "bottom_sections.content.slider_items.content",
  "top_sections.content.teasers.content",
  "middle_sections.content.teasers.content",
  "bottom_sections.content.teasers.content",
  "middle_sections.content.fields.content", // FlexibleForm
  "bottom_sections.content.fields.fields.field", // FlexibleForm
  "top_sections.content.slides",
  "bottom_sections.content.slides",
]);

export const contentPageReferences: readonly string[] = Object.freeze([...sectionReferences]);

export const brandListPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_section.top_sections",
  "bottom_sections.content",
]);
export const cartPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_sections.content",
  "bottom_sections.content",
]);

export const headerReferences: readonly string[] = Object.freeze([
  "top_header.logo",
  "top_header.meta_nav",
  "top_header.meta_nav.aria_label",
  "top_header.main_header.aria_label_actions",
]);

export const footerReferences: readonly string[] = Object.freeze([
  "usp.usp_list.link.internal_link",
  "footer_nav.column_1.links.internal_link",
  "footer_nav.column_2.links.internal_link",
  "footer_nav.column_3.links.internal_link",
  "footer_nav.column_4.links.internal_link",
  "trust_area.link.internal_link",
  "legal_nav.legal_links.internal_link",
  "newsletter.newsletter_cta.internal_link",
]);

export const brandsDetailsPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_sections.content",
  "middle_sections.content",
  "bottom_sections.content",
]);

export const leafletsDetailsPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_sections.content",
  "bottom_sections.content",
]);

export const leafletsOverviewPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_sections.content",
  "bottom_sections.content",
]);

export const locationDetailsPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_sections.content",
  "middle_sections.content",
  "bottom_sections.content",
]);

export const locationListPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_sections.content",
  "middle_sections.content",
  "bottom_sections.content",
]);

export const loginPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_sections.content",
  "bottom_sections.content",
]);

export const logoutPageReferences: readonly string[] = Object.freeze(["sections.content"]);

export const registrationPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_sections.content",
  "bottom_sections.content",
]);

export const resetPasswordPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_sections.content",
  "bottom_sections.content",
]);

export const forgottenPasswordPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_sections.content",
  "bottom_sections.content",
]);

export const productPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_sections.content",
  "middle_sections.content",
  "bottom_sections.content",
]);

export const searchResultsPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_sections.content",
  "bottom_sections.content",
]);

export const navigationIndexReferences: readonly string[] = Object.freeze([
  "parent_navigation_element",
  "parent_navigation_element.page_link",
  "page_link",
  "sections.content",
]);

export const categoryIndexReferences: readonly string[] = Object.freeze([
  "index.parents",
  "index.parents.index.parents",
]);

export const registrationConfirmationPageReferences: readonly string[] = Object.freeze([
  ...subsectionReferences,
  "top_sections.content",
  "bottom_sections.content",
]);
