<template>
  <article class="product-tile" data-tosca="plp-article-area">
    <div class="product-tile__top">
      <spar-link class="product-title__link" :link="shopUrl" :title="name2">
        <span class="product-tile__info">
          <span v-if="name1" data-tosca="plp-row1-brand" class="product-tile__brand">
            {{ name1 }}
          </span>
          <span v-if="name2" data-tosca="plp-row2-title" class="product-tile__title">
            {{ name2 }}
          </span>
          <span
            v-if="name3 || name4"
            data-tosca="plp-additional-info"
            class="product-tile__aditional-info"
          >
            {{ name3 }}&nbsp;{{ name4 }}
          </span>
        </span>
      </spar-link>

      <spar-button
        class="product-tile__wishlist btn--clear"
        data-tosca="plp-favorite"
        :variant="ButtonVariant.custom"
        :title="$t('wishlist.add_item')"
        :aria-label="$t('wishlist.add_item')"
        @click="toggleWishlist"
      >
        <!-- <spar-icon-sprite :symbol="wishlistSymbol"></spar-icon-sprite> -->
      </spar-button>

      <!-- TODO change link to shopUrl if no longer test-geo factfinder is in use -->
      <spar-link
        class="product-tile__image-link product-title__link"
        :link="`/p/${productId}`"
        :title="name2"
        :variant="LinkVariant.link"
      >
        <div class="product-tile__image-wrapper">
          <spar-adaptive-image
            data-tosca="plp-image"
            object-fit="contain"
            class="product-tile__image"
            :src="tilePicture.assetUrl"
            :alt="tilePicture.altText"
          ></spar-adaptive-image>
        </div>
      </spar-link>

      <!-- <div v-if="badgeSlots.length && badgeSlots[0]" class="product-tile__badges">
        <div
          v-for="(badge, badgeIndex) in badgeSlots[0].badgeSlot1"
          :key="badgeIndex"
          class="product-tile__badge-img-wrapper"
        >
          <spar-adaptive-image
            class="product-tile__badge-img"
            :src="badge.badgeDamUrl"
            :alt="badge.badgeAltText"
          ></spar-adaptive-image>
        </div>
      </div> -->
    </div>

    <spar-availability-product
      :product="locationOverlayProduct"
      :is-loading="isLoadingOutletAvailability"
      :is-available="
        outletAvailability && outletAvailability.availability === OutletAvailability.AVAILABLE
      "
      class="product-tile__availability-button"
    />

    <div class="product-tile__buy-box">
      <!-- <spar-buy-box-small :tile-sales-price="tileSalesPrice" /> -->

      <!-- <spar-buy-box v-bind="props" :box-format="BuyBoxFormat.small" /> -->
    </div>
  </article>
</template>

<script lang="ts" setup>
import type { LocationOverlayProduct } from "~/components/feature/SparLocation/SparLocationOverlay/SparLocationOverlay.types";
import SparAdaptiveImage from "~/components/shared/SparAdaptiveImage/SparAdaptiveImage.vue";
import SparAvailabilityProduct from "~/components/shared/SparAvailabilityProduct/SparAvailabilityProduct.vue";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import { LinkVariant } from "~/components/shared/SparLink/SparLink.types";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import useI18n from "~/composables/i18n/useI18n";
import type { ProductSchemaV1BadgeSlotsValueInner } from "~/types/mdsa.types";
import {
  OutletAvailability,
  type OutletAvailabilitySchema,
} from "~/utils/availability/integration/availability.types";
import type { GeoInformation } from "~/utils/factfinder/integration/factfinder.types";
import type { Asset } from "~/utils/mdsa/integration/mdsa.types";

// import SparBuyBoxSmall from "../SparBuyBox/SparBuyBoxSmall/SparBuyBoxSmall.vue";
// import type { SparProductTileProps } from "./SparProductTile.types";

// const props: SparProductTileProps = defineProps({
const props = defineProps({
  productId: {
    type: String,
    required: true,
  },
  name1: {
    type: String,
    default: "",
  },
  name2: {
    type: String,
    default: "",
  },
  name3: {
    type: String,
    default: "",
  },
  name4: {
    type: String,
    default: "",
  },
  tilePicture: {
    type: Object as PropType<Asset>,
    default: null,
  },
  tileSalesPrice: {
    type: String,
    default: "",
  },
  badgeSlots: {
    type: Object as PropType<Record<string, ProductSchemaV1BadgeSlotsValueInner[]>>,
    default: null,
  },
  minOrderQuantityP: {
    type: Number,
    default: 0,
  },
  minOrderQuantityT: {
    type: Number,
    default: 0,
  },
  maxOrderQuantityP: {
    type: Number,
    default: 0, // value must always be provided by hybris/mdsa
  },
  maxOrderQuantityT: {
    type: Number,
    default: 0, // value must always be provided by hybris/mdsa
  },
  // prices: {
  //   type: Object as PropType<SparProduct["prices"]>,
  //   required: true,
  // },
  sparProductType: {
    type: String,
    default: "",
  },
  shopUrl: {
    type: String,
    default: "",
  },
  geoInformation: {
    type: Object as PropType<GeoInformation[]>,
    default: null,
  },
});

const { loadOutletAvailability } = useLocationStore();
const { favouriteStore } = useFavouriteStore();
const { $t } = useI18n();
// const { favouriteStore } = useFavouriteStore();

const outletAvailability = ref<OutletAvailabilitySchema>();
const isLoadingOutletAvailability = ref(false);

const handleLoadOutletAvailability = async () => {
  if (!favouriteStore.value?.storeId) return;

  isLoadingOutletAvailability.value = true;
  outletAvailability.value = await loadOutletAvailability(
    props.productId,
    favouriteStore.value?.storeId,
  );
  isLoadingOutletAvailability.value = false;
};

const tempFavStoreId = ref(favouriteStore.value?.storeId);
watch(
  () => favouriteStore.value,
  async (favStore) => {
    if (favStore && favouriteStore.value?.storeId && favStore.storeId !== tempFavStoreId.value) {
      await handleLoadOutletAvailability();
      // check availability if favStore changed
      tempFavStoreId.value = favStore.storeId;
    }
  },
);

onMounted(async () => {
  await handleLoadOutletAvailability();
});

// const wishlistSymbol = ref("heart");

const toggleWishlist = () => {
  // wishlistSymbol.value = "heart-filled";
};

const locationOverlayProduct = computed(() => {
  const res: LocationOverlayProduct = {
    productId: props.productId,
    name1: props.name1,
    name2: props.name2,
    imgSrc: props.tilePicture.servletBaseUrl || "",
    imgAlt: props.tilePicture.altText,
  };

  return res;
});
</script>

<style lang="scss">
@use "./SparProductTile.scss";
</style>
