<template>
  <div v-if="!isLoading" class="spar-search">
    <ul class="spar-search__categories">
      <spar-search-category
        v-for="category in categories"
        :key="category.key"
        :label="category.label"
        :count="searchResults[category.key].totalHits || 0"
        @click="jumpTo(category.key)"
      />
    </ul>

    <div
      v-if="
        !searchResults[SearchCategory.products].totalHits &&
        !searchResults[SearchCategory.topics].totalHits
      "
    >
      <p class="spar-search__no-results">{{ $t("search.groups.no_results") }}</p>
    </div>
    <div v-else class="spar-search__results-container">
      <div v-if="searchMode !== SearchMode.topics" class="spar-search__product-results">
        <div class="spar-search__categories-header">
          <spar-heading
            :id="SearchCategory.products"
            level="2"
            headline-style="2"
            :title="
              $t('search.groups.products.title') +
              `&nbsp;(${searchResults[SearchCategory.products].totalHits})`
            "
          />
          <spar-link
            v-if="searchResults[SearchCategory.products].totalHits"
            icon="arrow-right"
            icon-position="right"
            :variant="LinkVariant.linkIcon"
            tosca-prefix="plp-showall"
            @click="onProductsLinkClick"
          >
            {{ $t("search.actions.show_all_generic") }}
          </spar-link>
        </div>

        <section
          v-if="searchResults[SearchCategory.products].totalHits"
          class="spar-search__results"
        >
          <div class="spar-search__results-tiles">
            <spar-product-tile
              v-for="product in products"
              v-bind="product"
              :key="product.productId"
            ></spar-product-tile>
          </div>
          <div class="spar-search__results-button">
            <spar-button
              :label="
                $t('search.actions.show_all', {
                  count: searchResults[SearchCategory.products].totalHits?.toString() || '0',
                  group: $t('search.groups.products.title'),
                })
              "
              data-tosca="plp-showall-btn"
              @click="onProductsLinkClick"
            />
          </div>
        </section>
        <p v-else class="spar-search__no-results">{{ $t("search.groups.products.no_results") }}</p>
      </div>

      <div v-if="searchMode !== SearchMode.products" class="spar-search__content-results">
        <div class="spar-search__categories-header">
          <spar-heading
            :id="SearchCategory.topics"
            class="heading--left"
            level="2"
            headline-style="2"
            :title="
              $t('search.groups.topics.header', {
                query: searchTerm,
              }) + `&nbsp;(${searchResults[SearchCategory.topics].totalHits})`
            "
          />
        </div>
        <div v-if="searchResults[SearchCategory.topics].totalHits">
          <div class="spar-search__content-results-data">
            <spar-topic-tile v-for="topic in topics" :key="topic.slug" :topic="topic" />
          </div>
          <div class="spar-search__results-button">
            <spar-button
              v-if="currentTopicsPage < pageCount"
              :label="$t('search.actions.show_more')"
              data-tosca="plp-showmore-btn"
              @click="loadMoreTopics"
            />
          </div>
        </div>
        <p v-else class="spar-search__no-results">{{ $t("search.groups.topics.no_results") }}</p>
      </div>
    </div>
  </div>
  <!-- TODO: Needs refinement for A11y AND optimization for loader per category -->
  <div v-else class="spar-search__loader">
    <spar-loader />
  </div>
</template>

<script setup lang="ts">
import { SearchMode } from "~/components/sections/SparHeader/SparSearchField/SparSearchField.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import { LinkVariant } from "~/components/shared/SparLink/SparLink.types";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import SparLoader from "~/components/shared/SparLoader/SparLoader.vue";
import SparProductTile from "~/components/shared/SparProductTile/SparProductTile.vue";
import SparSearchCategory from "~/components/shared/SparSearchCategory/SparSearchCategory.vue";
import useI18n from "~/composables/i18n/useI18n";
import { useSearchStore } from "~/stores/search.store";
import { scrollToId } from "~/utils/ui";
import { SearchCategory } from "./SparSearch.types";
import SparTopicTile from "./SparTopicTile/SparTopicTile.vue";

const { $t } = useI18n();
const { searchResults, loadMoreTopics, getConfigForCategory } = useSearchStore();
const { currentTopicsPage, isLoading, searchMode, searchTerm, urlQuery } =
  storeToRefs(useSearchStore());
const router = useRouter();

const topicsConfig = getConfigForCategory(SearchCategory.topics);

const categories = computed(() => {
  const keys = Object.keys(SearchCategory);
  return keys.map((key) => {
    return {
      key,
      label: $t(`search.groups.${key}.title`),
    };
  });
});

const products = computed(() => {
  const { resolvedProducts } = searchResults[SearchCategory.products];
  if (!resolvedProducts) return [];
  return resolvedProducts.slice(0, 4);
});

const topics = computed(() => {
  const { resolvedTopics } = searchResults[SearchCategory.topics];
  return resolvedTopics || [];
});

const jumpTo = (anchor: string) => {
  scrollToId(anchor);
};

// TODO: This is a temporary solution to show more results
const onProductsLinkClick = () => {
  router.push("/c/blt293367f064f75055?search=" + urlQuery.value.search);
};

const pageCount = computed(() =>
  Math.ceil((searchResults[SearchCategory.topics].totalHits || 0) / topicsConfig.hitsPerPage),
);
</script>

<style lang="scss">
@use "./SparSearch.scss";
@use "~/pages/category/category.scss";
</style>
