<template>
  <spar-button
    class="availability-product__button"
    :variant="ButtonVariant.custom"
    :aria-label="
      !favouriteStore?.storeId
        ? $t('show_product_on_map_available')
        : isAvailable
          ? $t('favourite_store.available')
          : $t('favourite_store.not_available')
    "
    tosca-prefix="brand-list-nav-letter"
    :icon="!favouriteStore?.storeId || isLoading ? '' : isAvailable ? 'available' : 'not-available'"
    icon-position="left"
    :class="{
      'availability-product__location-available': isAvailable,
      'availability-product__location-not-available': !isAvailable,
      'availability-product__location-info': !favouriteStore?.storeId,
    }"
    @click="selectAvailabilityProduct"
  >
    <span v-if="!favouriteStore?.storeId || isLoading">
      <spar-icon-sprite symbol="info" />
      {{ $t("location.map.show_products.first") }}
      <span class="availability-product__additional-text">{{
        $t("location.map.show_products.second")
      }}</span>
    </span>
    <span v-else>
      {{ isAvailable ? $t("favourite_store.available") : $t("favourite_store.not_available") }}
      <span class="availability-product__additional-text">{{ $t("favourite_store.suffix") }}</span>
    </span>
  </spar-button>
</template>

<script lang="ts" setup>
import type { LocationOverlayProduct } from "~/components/feature/SparLocation/SparLocationOverlay/SparLocationOverlay.types";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";

const { showMapOverlay, selectedProduct } = storeToRefs(useLocationStore());
const { favouriteStore } = useFavouriteStore();

const { $t } = useI18n();

const props = defineProps({
  product: {
    type: Object as PropType<LocationOverlayProduct>,
    default: undefined,
  },
  isAvailable: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const selectAvailabilityProduct = () => {
  selectedProduct.value = props.product;
  showMapOverlay.value = true;
};
</script>

<style lang="scss">
@use "./SparAvailabilityProduct.scss";
</style>
