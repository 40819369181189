<template>
  <div v-if="product" class="pdp-product">
    <spar-lightbox
      :items="onlyVideos"
      :lightbox-open-index="lightboxOpenIndex"
      @close-light-box="lightboxOpenIndex = null"
    ></spar-lightbox>
    <div class="pdp-product__main">
      <div v-if="galleryAssets.length" class="pdp-product__media">
        <!-- <div
          v-if="product.badgeSlots && product.badgeSlots[0].badgeSlot2"
          class="pdp-product__badges-media"
        >
          <div
            v-for="(badge, index) in product.badgeSlots[0].badgeSlot2"
            :key="index"
            class="pdp-product__badge-outer"
          >
            <div class="pdp-product__badge-img-wrapper">
              <spar-adaptive-image
                v-if="badge"
                class="pdp-product__badge-img"
                object-fit="contain"
                :src="badge.badgeDamUrl"
                :alt="badge.badgeAltText"
              ></spar-adaptive-image>
            </div>
          </div>
        </div> -->

        <div class="pdp-product__media-inner">
          <spar-slider
            :items-count="galleryAssets.length"
            :dots-as-images="true"
            :assets="galleryAssets"
            :show-count="true"
            @slide-changing="setSlideChanging"
          >
            <spar-slider-item
              v-for="(asset, index) in galleryAssets"
              :key="index"
              :role="
                asset.type === productSchemaV1AssetsInnerTypeEnum.Video && !isSlideChanging
                  ? 'button'
                  : ''
              "
              :aria-label="$t('lightbox.start_video')"
              @click="openLightBox(asset)"
              @keypress.enter="openLightBox(asset)"
            >
              <div
                class="pdp-product__media-img-wrapper"
                :class="{
                  'pdp-product__media--clickable':
                    asset.type === productSchemaV1AssetsInnerTypeEnum.Video,
                }"
              >
                <spar-adaptive-image
                  :tosca-prefix="`pdp-slider-items-${index}`"
                  object-fit="contain"
                  class="pdp-product__media-img"
                  :src="asset.src"
                  :alt="asset.alt || $t('pdp.product.image.alt_fallback')"
                />

                <div
                  v-if="asset.type === productSchemaV1AssetsInnerTypeEnum.Video"
                  class="pdp-product__media-play btn btn--play"
                >
                  <spar-icon-sprite symbol="play"></spar-icon-sprite>
                </div>
              </div>
            </spar-slider-item>
          </spar-slider>
        </div>
      </div>
      <div class="pdp-product__info-wrapper">
        <div class="pdp-product__info">
          <span v-if="product.name1" class="pdp-product__brand" data-tosca="pdp-brand">
            {{ product.name1 }}
          </span>

          <h1 v-if="product.name2" class="pdp-product__title" data-tosca="pdp-title">
            {{ product.name2 }}
          </h1>
          <span
            v-if="product.name3 || product.name4"
            class="pdp-product__aditional-info"
            data-tosca="pdp-additional-info"
          >
            {{ product.name3 }}&nbsp;{{ product.name4 }}
          </span>
        </div>

        <!-- <spar-link
          v-if="!noInfoLink"
          class="pdp-inpaket-inpronto-link"
          target="_blank"
          icon="info"
          icon-position="left"
          :variant="LinkVariant.linkIcon"
          tosca-prefix="pdp-delivery-info-link"
          @click="showDeliveryOverlay"
        >
          {{
            product.availabilityTypes?.length === 2
              ? $t("pdp.inpaket_inpronto.link")
              : product.availabilityTypes?.[0] === AvailabilityTypes.INPAKET
                ? $t("pdp.inpaket.link")
                : product.availabilityTypes?.[0] === AvailabilityTypes.INPRONTO
                  ? $t("pdp.inpronto.link")
                  : ""
          }}</spar-link
        >

        <spar-overlay
          class="pdp-overlay-inpronto-inpaket"
          :is-active="isActiveOverlay"
          role="alertdialog"
          aria-describedby="pdp-delivery-info-overlay"
          tosca-prefix="pdp-delivery-info-overlay"
          :title="$t('pdp.overlay.title')"
          @close="isActiveOverlay = false"
        >
          <div class="pdp-delivery__description">
            <div class="pdp-delivery__description-row">
              <div class="pdp-delivery__icon">
                <spar-icon-sprite class="pdp-delivery__icon-sprite" symbol="in" />
                {{ $t("plp.product.tile.availability.p") }}
              </div>
              <div class="pdp-overlay__info-text">
                <p class="pdp-overlay__label">
                  {{ $t("pdp.overlay.inpaket.label") }}
                </p>
                {{ $t("pdp.overlay.inpaket.text") }}
              </div>
            </div>
            <div class="pdp-delivery__description-row">
              <div class="pdp-delivery__icon">
                <spar-icon-sprite class="pdp-delivery__icon-sprite" symbol="in" />
                {{ $t("plp.product.tile.availability.t") }}
              </div>
              <div class="pdp-overlay__info-text">
                <p class="pdp-overlay__label">
                  {{ $t("pdp.overlay.inpronto.label") }}
                </p>
                {{ $t("pdp.overlay.inpronto.text") }}
                <span class="pdp-overlay__link-t">
                  <a :href="$t('pdp.inpronto.text.link')">{{ $t("pdp.inpronto.text.label") }}</a>
                </span>
              </div>
            </div>
          </div>
        </spar-overlay> -->

        <spar-buy-box-big
          :shop-context="shopContext"
          :box-format="BuyBoxFormat.big"
          :product="product"
          :product-price="productPrice?.prices"
          :ecom-availability="
            ecomAvailability?.availabilityTypes?.filter(
              (availbility) => availbility.availabilityType === shopContext,
            )[0]
          "
          :outlet-availability="outletAvailability"
        >
        </spar-buy-box-big>
        <!-- <div v-if="product" class="pdp-product__buybox">
          <spar-buy-box
            :additional-services="product.additionalServices"
            :availability-types="product.availabilityTypes"
            :availability="product.availability"
            :box-format="BuyBoxFormat.big"
            :main-info="product.mainInfo"
            :min-order-quantity-t="product.minOrderQuantityT"
            :min-order-quantity-p="product.minOrderQuantityP"
            :max-order-quantity-t="product.maxOrderQuantityT"
            :max-order-quantity-p="product.maxOrderQuantityP"
            :pictures="product.pictures"
            :prices="product.prices"
            :spar-product-type="product.sparProductType"
          />
        </div> -->
      </div>
    </div>

    <div class="pdp__product-bottom">
      <!-- <div
        v-if="product.tradeItemMarketingMessage"
        class="pdp__product-info"
        v-html="product.tradeItemMarketingMessage"
      ></div> -->

      <spar-product-details />

      <!-- <div class="pdp__cross-selling">
        <slot v-if="$slots.crossSelling" name="crossSelling"></slot>
      </div> -->
    </div>

    <div class="pdp__dynamic-product-information">8 - Dynamic product info MDSA</div>
  </div>
</template>

<script lang="ts" setup>
import SparAdaptiveImage from "~/components/shared/SparAdaptiveImage/SparAdaptiveImage.vue";
import { BuyBoxFormat } from "~/components/shared/SparBuyBox/SparBuyBox.types";
import SparBuyBoxBig from "~/components/shared/SparBuyBox/SparBuyBoxBig/SparBuyBoxBig.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import type { SparLightboxItem } from "~/components/shared/SparLightbox/SparLightbox.types";
import SparLightbox from "~/components/shared/SparLightbox/SparLightbox.vue";
import SparSlider from "~/components/shared/SparSlider/SparSlider.vue";
import SparSliderItem from "~/components/shared/SparSlider/SparSliderItem/SparSliderItem.vue";
import { productSchemaV1AssetsInnerTypeEnum, type AvailabilityType } from "~/types/mdsa.types";
import type { OutletAvailabilitySchema } from "~/utils/availability/integration/availability.types";
import SparProductDetails from "./SparProductDetails/SparProductDetails.vue";
import { usePdpProduct } from "./usePdpProduct";

const { $t } = useI18n();
const { loadOutletAvailability } = useLocationStore();
const { ecomAvailability } = storeToRefs(useLocationStore());
const { favouriteStore } = useFavouriteStore();
const { product } = storeToRefs(useMdsaStore());
const { productPrice } = storeToRefs(usePPCStore());
const { galleryAssets } = usePdpProduct(product.value);

const outletAvailability = ref<OutletAvailabilitySchema>();
const lightboxOpenIndex: Ref<number | null> = ref(null);
const isSlideChanging = ref(false);
//const isActiveOverlay = ref(false);
// currently set Fixed to "P" - in future get context from url? store? when decided
const shopContext = ref<AvailabilityType>("P");

if (favouriteStore.value?.storeId && product.value) {
  outletAvailability.value = await loadOutletAvailability(
    product.value.productId,
    favouriteStore.value?.storeId,
  );
}

const tempFavStoreId = ref(favouriteStore.value?.storeId);
watch(
  () => favouriteStore.value,
  async (favStore) => {
    if (favStore?.storeId && favStore.storeId !== tempFavStoreId.value && product.value) {
      // check availability if favStore changed
      outletAvailability.value = await loadOutletAvailability(
        product.value.productId,
        favStore.storeId,
      );

      tempFavStoreId.value = favStore.storeId;
    }
  },
);

// const noInfoLink = computed(
//   () =>
//     product.value.sparProductType === SparProductType.Voucher ||
//     product.value.sparProductType === SparProductType.GWK,
// );

// const AvailabilityTypes = {
//   INPAKET: "P",
//   INPRONTO: "T",
// };

// function showDeliveryOverlay(): void {
//   isActiveOverlay.value = true;
// }

const setSlideChanging = (changing: boolean) => {
  isSlideChanging.value = changing;
};

const openLightBox = (asset: SparLightboxItem) => {
  if (asset.type === productSchemaV1AssetsInnerTypeEnum.Video && !isSlideChanging.value) {
    lightboxOpenIndex.value = asset.id;
  }
};

const onlyVideos = galleryAssets.value.filter(
  (item) => item.type === productSchemaV1AssetsInnerTypeEnum.Video,
);
</script>

<style lang="scss">
@use "SparPdpProduct.scss";
</style>
