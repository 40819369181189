export const RegisteredComponents = {
  SparAccordion: "SparAccordion",
  SparAnchor: "SparAnchor",
  SparBanner: "SparBanner",
  SparCookieList: "SparCookieList",
  SparContactForm: "SparContactForm",
  SparContentSlider: "SparContentSlider",
  SparFlexibleForm: "SparFlexibleForm",
  SparHeading: "SparHeading",
  SparHero: "SparHero",
  SparImageTeaser: "SparImageTeaser",
  SparImageTextTeaser: "SparImageTextTeaser",
  SparInspirationWorld: "SparInspirationWorld",
  SparTeaserblock: "SparTeaserblock",
  SparTextImageVideo: "SparTextImageVideo",
  SparTextTeaser: "SparTextTeaser",
  SparUsp: "SparUsp",
} as const;
export type RegisteredComponents = (typeof RegisteredComponents)[keyof typeof RegisteredComponents];
