/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface PriceSchemaV1 {
  Product?: PriceSchemaV1Product;
  _meta?: PriceSchemaV1Meta;
  productId?: string;
}
export const itemUnit = {
  Kg: "kg",
  G: "g",
  L: "l",
  Ml: "ml",
  Eve: "eve",
  Gve: "gve",
  Pal: "pal",
  Wg: "wg",
} as const;
export type ItemUnit = (typeof itemUnit)[keyof typeof itemUnit];
/**
 * Product
 * detailed product export specification: https://collaboration.spar.at/confluence/display/ES/Product+Data+Export
 */
export interface ProductSchemaV1 {
  _meta?: ProductSchemaV1Meta;
  productId: string;
  name1?: string;
  name2?: string;
  name3?: string;
  name4?: string;
  /** Trigger if product can be also shipped abroad */
  excludeDeliveryAbroad?: boolean;
  /** Optional attribute for Dropshipment Products. Contains a delivery time which overrules the default delivery time */
  deliveryTimeException?: string;
  deliveryModes?: ProductSchemaV1DeliveryModesInner[];
  /** SupplierInfo Object is needed to display product in context of different suppliers. Ex. Food product 4711 is produced in Salzurg and Vienna and each production site uses a different recipie to produce the product then allergenic infos might differ according to its supplier. The Object SupplierInfos contains links to MDSA Product Calls with supplier reference */
  SupplierInfos?: ProductSchemaV1SupplierInfosInner[];
  /** Pimcore classification store attribute, but different json format, because attribute cannot be maintained multiple time for one Product. Source ZLMIV Grunddaten */
  productName?: string;
  /** Pimcore classification store attribute, but different json format, because attribute cannot be maintained multiple time for one Product. Source ZLMIV Grunddaten */
  productDescriptionVendor?: string;
  /** Pimcore classification store attribute, but different json format, because attribute cannot be maintained multiple time for one Product. Source ZLMIV Grunddaten */
  marketingText?: string;
  /** Pimcore classification store attribute, but different json format, because attribute cannot be maintained multiple time for one Product. Source ZLMIV Grunddaten */
  marketingMessage?: string;
  /** Pimcore classification store attribute. Contains Ursprungsland/Herkunftsort */
  provenanceStatement?: ProductSchemaV1ProvenanceStatement;
  /** Pimcore classification store attribute. Contains Grunddaten - Artikelinhaltsinfo */
  productContentInformation?: ProductSchemaV1ProductContentInformation;
  /** Pimcore classification store attribute. Contains Labels - Biokontrollstelle */
  bioControlPoint?: ProductSchemaV1BioControlPoint;
  /** Pimcore classification store attribute. Contains Labels - Oekoherkunft */
  organicOrigin?: ProductSchemaV1OrganicOrigin;
  /** Pimcore classification store attribute. Contains Labels - Identitaetscode */
  identityCode?: ProductSchemaV1IdentityCode;
  /** Pimcore classification store attribute. Contains Grunddaten Artikelzusatztext */
  additionalProductDescription?: ProductSchemaV1AdditionalProductDescription;
  /** Pimcore classification store attribute. Contains Aufbewahrug-Lagerhinweistext */
  applicationNote?: ProductSchemaV1ApplicationNote;
  /** Pimcore classification store attribute. Contains Aufbewahrung-Verwendungshinweis */
  usageNote?: ProductSchemaV1UsageNote;
  /** Pimcore classification store attribute. Verfallsdatum */
  guaranteedBestBeforeDate?: ProductSchemaV1GuaranteedBestBeforeDate;
  /** Pimcore classification store attribute. Aufbewahrung-Entsorungshinweistext */
  disposalInstructionText?: ProductSchemaV1DisposalInstructionText;
  /** Pimcore classification store attribute. Contains Sicherheit - Gefahrghssignal */
  GHSSignal?: ProductSchemaV1GHSSignal;
  /** Pimcore classification store attribute. Contains Sicherheit - GEFAHRGHHSATZ */
  hazardStatementsCodes?: ProductSchemaV1HazardStatementsCodes;
  /** Pimcore classification store attribute. Contains Sicherheit - Gefahrghspsatz */
  precautionaryStatementsCodes?: ProductSchemaV1PrecautionaryStatementsCodes;
  /** Pimcore classification store attribute. Contains Sicherheit - Gefahrghshstatztext */
  hazardStatementsDescriptions?: ProductSchemaV1HazardStatementsDescriptions;
  /** Pimcore classification store attribute. Contains Sicherheit - Gefahrghspstatztext  */
  precautionaryStatementsDescriptions?: ProductSchemaV1PrecautionaryStatementsDescriptions;
  /** Pimcore classification store attribute. Contains Sicherheit - Dosierempfehlung */
  recommendetDosage?: ProductSchemaV1RecommendetDosage;
  /** Pimcore classification store attribute. Contains Sicherheit - Detergenziendatenblattlink */
  detergensDataSheets?: ProductSchemaV1DetergensDataSheets;
  /** Pimcore classification store attribute. Contains Sicherheit - Produktkategorie */
  legalProductCategories?: ProductSchemaV1LegalProductCategories;
  /** Pimcore classification store attribute. Contains Sicherheit - Biozid */
  biocide?: ProductSchemaV1Biocide;
  /** Pimcore classification store attribute. Contains Sicherheit - Haftungsklausel */
  liabilityClauseLink?: ProductSchemaV1LiabilityClauseLink;
  /** Pimcore classification store attribute. Contains Sicherheit Medizinproduktklasse */
  medicalDeviceClass?: ProductSchemaV1MedicalDeviceClass;
  /** Pimcore classification store attribute. Contains Sicherheit - Sicherheitsdatenblattlink */
  securityDataSheets?: ProductSchemaV1SecurityDataSheets;
  /** Pimcore classification store attribute. Contains Sicherheit - Warenkennzeichnung */
  compulsoryAdditiveLabelInformation?: ProductSchemaV1CompulsoryAdditiveLabelInformation;
  /** Pimcore classification store attribute. Contains Service - Garantiedauer */
  guarantee?: ProductSchemaV1Guarantee;
  /** Pimcore classification store attribute. Contains values of several LMIV Attributes. Kontaktname, Kontaktadresse, Kontaktkanal */
  contactChannels?: ProductSchemaV1ContactChannels;
  /** Pimcore classification store attribute. Contains value of LMIV Attribute Nährwerte - Nährwertanspruch */
  geneticNote?: ProductSchemaV1GeneticNote;
  /** Pimcore classification store attribute. Contains value of LMIV Attribute Nährwerte - Nährwertanspruchscode */
  nutritionClaimNote?: ProductSchemaV1NutritionClaimNote;
  /** Pimcore classification store attribute. Contains value of LMIV Attribute Nährwerte - Zubereitungsart */
  nutritionClaim?: ProductSchemaV1NutritionClaim;
  /** Pimcore classification store attribute. Contains value of LMIV Attribute Nährwerte - Zubereitungsart */
  modeOfPreparations?: ProductSchemaV1NutritionClaim;
  /** Pimcore classification store attribute. Contains value of LMIV Attribute Nährwerte - Zubereitungsanweisung */
  instruction?: ProductSchemaV1Instruction;
  /** Pimcore classification store attribute. Contains value of LMIV Attribute Nährwerte - Portionsanzahltext */
  numberOfPortionsText?: ProductSchemaV1NumberOfPortionsText;
  /** The sparProductType defines, which kind of product we are dealing with. Depending on certain attributes, or ERP Informations the sparProductType is set. Detailed description https://collaboration.spar.at/confluence/display/ES/SparProductType */
  sparProductType?: ProductSchemaV1SparProductTypeEnum;
  supplierId?: string;
  purchaseGroup?: string;
  purchaseGroupName?: string;
  erpTradeGroup?: string;
  erpTradeGroupName?: string;
  assortmentgrade?: string;
  assortmentgradeName?: string;
  variantType?: string;
  variantTypeText?: string;
  /** stands in releation to the salesUnit of the product. For ex. the product 4711 has the salesUnit eve, then the Gtin for the measure eve is the sales Gtin, even though the product might have several Gtins */
  salesGtin?: string;
  /** Sales unit describes in which unit an article is sold. For ex. certain meat is sold per g, TVs are sold per piece (eve) */
  salesUnit?: ProductSchemaV1SalesUnitEnum;
  grossWeight?: number;
  /** contains unit of the Product gross weight. For ex kg */
  weightUnit?: string;
  termsAndConditions?: string;
  /**
   * Info when the Product was modified the last time. Contains a timestamp in the following format 2022-11-21T22:29:09.000Z
   * @format date-time
   */
  modifiedTime?: string;
  /**
   * Info when the Product was created in Pimcore. Contains a timestamp in the following format 2022-11-21T22:29:09.000Z
   * @format date-time
   */
  creationTime?: string;
  /** Contains true or false, which indicates if Product belongs to a spar owned brand. For ex. Simpex */
  ownBrand?: boolean;
  /** Describes how many items of the prodcut the customer has to buy minimum. Never null or empty, if not maintained, filled with default values. The suffix T or P defines in which context the customer has this limitation. P stands for nationwide parcel delivery. T stands for timeslot delivery in defined delivery zones */
  minOrderQuantityP?: number;
  /** Describes how many items of the prodcut the customer can buy maximum. Never null or empty, if not maintained, filled with default values. The suffix T or P defines in which context the customer has this limitation. P stands for nationwide parcel delivery. T stands for timeslot delivery in defined delivery zones */
  maxOrderQuantityP?: number;
  /** Describes how many items of the prodcut the customer has to buy minimum. Never null or empty, if not maintained, filled with default values. The suffix T or P defines in which context the customer has this limitation. P stands for nationwide parcel delivery. T stands for timeslot delivery in defined delivery zones */
  minOrderQuantityT?: number;
  /** Describes how many items of the prodcut the customer can buy maximum. Never null or empty, if not maintained, filled with default values. The suffix T or P defines in which context the customer has this limitation. P stands for nationwide parcel delivery. T stands for timeslot delivery in defined delivery zones */
  maxOrderQuantityT?: number;
  /** default false, necessary for Shop BE Availability Engine to indicate if product can be reserved or not */
  overwriteReservable?: boolean;
  /** Breadcrum contains the shopCategory hierarchy, of the specific product Only relevant for the eComShop */
  breadcrumbs?: ProductSchemaV1BreadcrumbsInner[];
  /** Contains BadgeSlots and Lableslots, the slot number defines to container on which a badge will be shown, the sequence the position within the container */
  badgeSlots?: Record<string, ProductSchemaV1BadgeSlotsValueInner[]>;
  /** The price object contains all different Prices a Product can have. The PriceTypes can be default, P, T and TOffline */
  prices?: ProductSchemaV1Prices;
  /** assets array contains all assets relevant for a product (Images, videos, documents, brandimage) */
  assets?: ProductSchemaV1AssetsInner[];
  /** the object maintainableAttributes contains all product classification attributes which are marked with a sequence. The sequence is mainained in Pimcore */
  maintainableAttributes?: object;
  /** AllergeneInfo is a specific array and contains the fix defined attributes ingredientsList, allergenes, labelling, alcohol, content. Because of a different display logic these attributes are in a specific array */
  allergeneInfo?: ProductSchemaV1AllergeneInfo;
  /** contains fix set of attributes, all relevant for the nutrition table. All relevant attributes are Pimcore classificationStore Attributes */
  nutrition?: ProductSchemaV1Nutrition;
  /** Array which contains all categories a product is assigned to. Brand Categories, Shop Categories, ECR Categories ... */
  categories?: ProductSchemaV1CategoriesInner[];
  /** defines if the product is a parcel shop product or a timeslot product */
  availabilityTypes?: AvailabilityType[];
  /** Link to product availability information */
  availability?: ProductSchemaV1Availability;
  /** Array which contains all gtins of a product */
  gtinList?: ProductSchemaV1GtinListInner[];
  /** Array which contains all product2product relations */
  boms?: ProductSchemaV1BomsInner[];
}
export const availabilityType = {
  T: "T",
  P: "P",
} as const;
export type AvailabilityType = (typeof availabilityType)[keyof typeof availabilityType];
export const priceType = {
  Default: "default",
  P: "P",
  T: "T",
  Toffline: "TOffline",
} as const;
export type PriceType = (typeof priceType)[keyof typeof priceType];
/**
 * Product
 * detailed product export specification: https://collaboration.spar.at/confluence/display/ES/Product+Data+Export
 */
export interface ProductSchemaV2 {
  _meta?: ProductSchemaV1Meta;
  productId: string;
  name1?: string;
  name2?: string;
  name3?: string;
  name4?: string;
  /** Trigger if product can be also shipped abroad */
  excludeDeliveryAbroad?: boolean;
  /** Optional attribute for Dropshipment Products. Contains a delivery time which overrules the default delivery time */
  deliveryTimeException?: string;
  deliveryModes?: ProductSchemaV1DeliveryModesInner[];
  /** SupplierInfo Object is needed to display product in context of different suppliers. Ex. Food product 4711 is produced in Salzurg and Vienna and each production site uses a different recipie to produce the product then allergenic infos might differ according to its supplier. The Object SupplierInfos contains links to MDSA Product Calls with supplier reference */
  SupplierInfos?: ProductSchemaV1SupplierInfosInner[];
  /** Pimcore classification store attribute, but different json format, because attribute cannot be maintained multiple time for one Product. Source ZLMIV Grunddaten */
  productName?: string;
  /** Pimcore classification store attribute, but different json format, because attribute cannot be maintained multiple time for one Product. Source ZLMIV Grunddaten */
  productDescriptionVendor?: string;
  marketingText?: string;
  /** Pimcore classification store attribute, but different json format, because attribute cannot be maintained multiple time for one Product. Source ZLMIV Grunddaten */
  marketingMessage?: string;
  /** The sparProductType defines, which kind of product we are dealing with. Depending on certain attributes, or ERP Informations the sparProductType is set. Detailed description https://collaboration.spar.at/confluence/display/ES/SparProductType */
  sparProductType?: ProductSchemaV2SparProductTypeEnum;
  country?: string;
  private?: boolean;
  taxClassId?: string;
  supplierId?: string;
  supplierName?: string;
  purchaseGroup?: string;
  purchaseGroupName?: string;
  erpTradeGroup?: string;
  erpTradeGroupName?: string;
  assortmentgrade?: string;
  assortmentgradeName?: string;
  variantType?: string;
  variantTypeText?: string;
  /** stands in releation to the salesUnit of the product. For ex. the product 4711 has the salesUnit eve, then the Gtin for the measure eve is the sales Gtin, even though the product might have several Gtins */
  mainGtin?: string;
  /** Sales unit describes in which unit an article is sold. For ex. certain meat is sold per g, TVs are sold per piece (eve) */
  salesUnit?: ProductSchemaV2SalesUnitEnum;
  grossWeight?: number;
  /** contains unit of the Product gross weight. For ex kg */
  weightUnit?: string;
  termsAndConditions?: string;
  /**
   * Info when the Product was modified the last time. Contains a timestamp in the following format 2022-11-21T22:29:09.000Z
   * @format date-time
   */
  modifiedTime?: string;
  /**
   * Info when the Product was created in Pimcore. Contains a timestamp in the following format 2022-11-21T22:29:09.000Z
   * @format date-time
   */
  creationTime?: string;
  /** Contains true or false, which indicates if Product belongs to a spar owned brand. For ex. Simpex */
  ownBrand?: boolean;
  /** Describes sales ranking based on order quantity on ecr level 2 */
  salesRankEcrLevel2?: number;
  /** indicates if a product is new or not */
  salesStatus?: string;
  /** Describes how many items of the prodcut the customer has to buy minimum. Never null or empty, if not maintained, filled with default values. The suffix T or P defines in which context the customer has this limitation. P stands for nationwide parcel delivery. T stands for timeslot delivery in defined delivery zones */
  minOrderQuantityP?: number;
  /** Describes how many items of the prodcut the customer can buy maximum. Never null or empty, if not maintained, filled with default values. The suffix T or P defines in which context the customer has this limitation. P stands for nationwide parcel delivery. T stands for timeslot delivery in defined delivery zones */
  maxOrderQuantityP?: number;
  /** Describes how many items of the prodcut the customer has to buy minimum. Never null or empty, if not maintained, filled with default values. The suffix T or P defines in which context the customer has this limitation. P stands for nationwide parcel delivery. T stands for timeslot delivery in defined delivery zones */
  minOrderQuantityT?: number;
  /** Describes how many items of the prodcut the customer can buy maximum. Never null or empty, if not maintained, filled with default values. The suffix T or P defines in which context the customer has this limitation. P stands for nationwide parcel delivery. T stands for timeslot delivery in defined delivery zones */
  maxOrderQuantityT?: number;
  /** default false, necessary for Shop BE Availability Engine to indicate if product can be reserved or not */
  overwriteReservable?: boolean;
  /** Breadcrum contains the shopCategory hierarchy, of the specific product Only relevant for the eComShop */
  breadcrumbs?: ProductSchemaV2BreadcrumbsInner[];
  /** Contains BadgeSlots and Lableslots, the slot number defines to container on which a badge will be shown, the sequence the position within the container */
  badgeSlots?: Record<string, ProductSchemaV1BadgeSlotsValueInner[]>;
  /** The price object contains all different Prices a Product can have. The PriceTypes can be default, P, T and TOffline */
  prices?: ProductSchemaV1Prices;
  /** assets array contains all assets relevant for a product (image, video, document, brandimage) */
  assets?: ProductSchemaV2AssetsInner[];
  /** the object contains all product classification attributes without the sequence attribute */
  productClassificationStore?: Record<string, ClassificationStoreAttribute>;
  /** the object contains all product classification attributes with the sequence attribute */
  classificationStoreAttribute?: Record<string, ClassificationStoreAttribute>;
  /** AllergeneInfo is a specific array and contains the fix defined attributes ingredientsList, allergenes, labelling, alcohol, content. Because of a different display logic these attributes are in a specific array */
  allergeneInfo?: Record<string, ClassificationStoreAttribute>;
  /** contains fix set of attributes, all relevant for the nutrition table. All relevant attributes are Pimcore classificationStore Attributes */
  nutrition?: ProductSchemaV2Nutrition;
  /** Array which contains all categories a product is assigned to. Brand Categories, Shop Categories, ECR Categories ... */
  categories?: ProductSchemaV1CategoriesInner[];
  /** defines which users can see the product within the online infromation portal */
  userGroups?: string[];
  /** defines if the product is a parcel shop product or a timeslot product */
  availabilityTypes?: AvailabilityType[];
  /** Link to product availability information */
  availability?: ProductSchemaV1Availability;
  /** Array which contains all gtins of a product */
  gtinList?: ProductSchemaV2GtinListInner[];
  /** Array which contains all product2product relations from bomSource perspective */
  boms?: ProductSchemaV2BomsInner[];
  /** Array which contains all product2product relations from bomTarget perspective */
  bomParents?: ProductSchemaV2BomParentsInner[];
  boxFactor?: number;
  productStatus?: number;
}
/** Pimcore classification store attribute with a sequence */
export interface ClassificationStoreAttribute {
  feName?: string;
  sequence?: number;
  values?: ClassificationStoreValue[];
}
export interface ClassificationStoreValue {
  valueString?: string;
  valueBoolean?: boolean;
  amount?: number;
  unit?: string;
  occurrence?: string;
  nutritionGroup?: number;
}
export interface DnasalesdataV1 {
  GeschaeftsfeldId: string;
  LandId: string;
  ArtikelId: string;
  ErsteVerwendungTagId?: string;
  LetzteVerwendungTagId?: string;
  UmsatzBruttoG_180?: number;
  UmsatzBruttoG_90?: number;
  UmsatzBruttoG_30?: number;
  UmsatzBruttoG_7?: number;
  VerkaufsmengeG_180?: number;
  VerkaufsmengeG_90?: number;
  VerkaufsmengeG_30?: number;
  VerkaufsmengeG_7?: number;
  EventProcessedUtcTime?: string;
  PartitionId?: number;
  BlobName?: string;
  BlobLastModifiedUtcTime?: string;
}
export interface PriceSchemaV1ProductBadgesInner {
  badgeId?: string;
}
export interface PriceSchemaV1ProductComparisonPrice {
  comparisonPrice?: number;
  comparisonSource?: string;
}
export interface PriceSchemaV1ProductPromotionInner {
  discount?: number;
  promotionText?: string;
  promotionType?: string;
  /** @format time */
  validFrom?: string;
  /** @format time */
  validTo?: string;
}
export interface PriceSchemaV1ProductReferencePriceInner {
  amount?: number;
  price?: number;
  unit?: ItemUnit;
}
export interface PriceSchemaV1ProductSalesPrice {
  amount?: number;
  price?: number;
  recommendedRetailPrice?: number;
  unit?: ItemUnit;
  /** @format date */
  validFrom?: string;
  /** @format date */
  validTo?: string;
}
export interface PriceSchemaV1Product {
  Badges?: PriceSchemaV1ProductBadgesInner[];
  ComparisonPrice?: PriceSchemaV1ProductComparisonPrice;
  Promotion?: PriceSchemaV1ProductPromotionInner[];
  ReferencePrice?: PriceSchemaV1ProductReferencePriceInner[];
  SalesPrice?: PriceSchemaV1ProductSalesPrice;
  businessUnit?: string;
  /** @format date-time */
  calculatedForDateTime?: string;
  country?: PriceSchemaV1ProductCountryEnum;
  currency?: PriceSchemaV1ProductCurrencyEnum;
  depositPrice?: number;
  depositProduct?: string;
  onSale?: boolean;
  productId?: string;
  redFlag?: boolean;
  storeNumber?: string;
  taxRate?: number;
}
export interface PriceSchemaV1Meta {
  schema?: string;
  ts?: number;
  version?: string;
}
export interface ProductSchemaV1Meta {
  /** timestamp */
  ts?: number;
  version?: string;
  schema?: string;
}
export interface ProductSchemaV1DeliveryModesInner {
  deliveryModeId?: ProductSchemaV1DeliveryModesInnerDeliveryModeIdEnum;
  defaultDeliveryTime?: string;
  associatedAvailabilityType?: AvailabilityType;
}
export interface ProductSchemaV1SupplierInfosInner {
  pos?: string;
  supplier?: string;
  href?: string;
}
export interface ProductSchemaV1ProvenanceStatementValuesInner {
  valueString?: string;
}
/** Pimcore classification store attribute. Contains Ursprungsland/Herkunftsort */
export interface ProductSchemaV1ProvenanceStatement {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Grunddaten - Artikelinhaltsinfo */
export interface ProductSchemaV1ProductContentInformation {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Labels - Biokontrollstelle */
export interface ProductSchemaV1BioControlPoint {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  /**  The array values contains the attribute values of the corresponding attribute. These can be valueString, amount, unit, boolean, occurance */
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Labels - Oekoherkunft */
export interface ProductSchemaV1OrganicOrigin {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Labels - Identitaetscode */
export interface ProductSchemaV1IdentityCode {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Grunddaten Artikelzusatztext */
export interface ProductSchemaV1AdditionalProductDescription {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Aufbewahrug-Lagerhinweistext */
export interface ProductSchemaV1ApplicationNote {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Aufbewahrung-Verwendungshinweis */
export interface ProductSchemaV1UsageNote {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
export interface ProductSchemaV1GuaranteedBestBeforeDateValuesInner {
  amount?: number;
  unit?: string;
}
/** Pimcore classification store attribute. Verfallsdatum */
export interface ProductSchemaV1GuaranteedBestBeforeDate {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1GuaranteedBestBeforeDateValuesInner[];
}
/** Pimcore classification store attribute. Aufbewahrung-Entsorungshinweistext */
export interface ProductSchemaV1DisposalInstructionText {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Sicherheit - Gefahrghssignal */
export interface ProductSchemaV1GHSSignal {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Sicherheit - GEFAHRGHHSATZ */
export interface ProductSchemaV1HazardStatementsCodes {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Sicherheit - Gefahrghspsatz */
export interface ProductSchemaV1PrecautionaryStatementsCodes {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Sicherheit - Gefahrghshstatztext */
export interface ProductSchemaV1HazardStatementsDescriptions {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Sicherheit - Gefahrghspstatztext  */
export interface ProductSchemaV1PrecautionaryStatementsDescriptions {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Sicherheit - Dosierempfehlung */
export interface ProductSchemaV1RecommendetDosage {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Sicherheit - Detergenziendatenblattlink */
export interface ProductSchemaV1DetergensDataSheets {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Sicherheit - Produktkategorie */
export interface ProductSchemaV1LegalProductCategories {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
export interface ProductSchemaV1BiocideValuesInner {
  valueBoolean?: boolean;
}
/** Pimcore classification store attribute. Contains Sicherheit - Biozid */
export interface ProductSchemaV1Biocide {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1BiocideValuesInner[];
}
/** Pimcore classification store attribute. Contains Sicherheit - Haftungsklausel */
export interface ProductSchemaV1LiabilityClauseLink {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Sicherheit Medizinproduktklasse */
export interface ProductSchemaV1MedicalDeviceClass {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Sicherheit - Sicherheitsdatenblattlink */
export interface ProductSchemaV1SecurityDataSheets {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Sicherheit - Warenkennzeichnung */
export interface ProductSchemaV1CompulsoryAdditiveLabelInformation {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains Service - Garantiedauer */
export interface ProductSchemaV1Guarantee {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains values of several LMIV Attributes. Kontaktname, Kontaktadresse, Kontaktkanal */
export interface ProductSchemaV1ContactChannels {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains value of LMIV Attribute Nährwerte - Nährwertanspruch */
export interface ProductSchemaV1GeneticNote {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains value of LMIV Attribute Nährwerte - Nährwertanspruchscode */
export interface ProductSchemaV1NutritionClaimNote {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains value of LMIV Attribute Nährwerte - Zubereitungsart */
export interface ProductSchemaV1NutritionClaim {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains value of LMIV Attribute Nährwerte - Zubereitungsanweisung */
export interface ProductSchemaV1Instruction {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
/** Pimcore classification store attribute. Contains value of LMIV Attribute Nährwerte - Portionsanzahltext */
export interface ProductSchemaV1NumberOfPortionsText {
  /** Optional field, if maintained it contains the heading for the corresponding attribute */
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
export interface ProductSchemaV1BreadcrumbsInner {
  name?: string;
  categoryId?: string;
  level?: number;
  slug?: string;
  target?: string;
}
export interface ProductSchemaV1BadgeSlotsValueInner {
  badgeSequence?: number;
  /** Dam cdn URL of the corresponding Badgel or Label */
  badgeDamUrl?: string;
  /** Alttext of the corresponding Badgel or Label */
  badgesAltText?: string;
}
export interface ProductSchemaV1PricesDefault {
  priceType?: PriceType;
  productId?: string;
  lowestprice?: string;
  storenumber?: string;
  /** Link to MDSA Price Call */
  href?: string;
}
export interface ProductSchemaV1PricesP {
  priceType?: PriceType;
  productId?: string;
  storenumber?: string;
  /** Link to MDSA Price Call */
  href?: string;
}
export interface ProductSchemaV1PricesTStoresInner {
  storenumber?: string;
  /** Link to MDSA Price Call */
  href?: string;
}
export interface ProductSchemaV1PricesT {
  priceType?: PriceType;
  productId?: string;
  stores?: ProductSchemaV1PricesTStoresInner[];
}
/** The price object contains all different Prices a Product can have. The PriceTypes can be default, P, T and TOffline */
export interface ProductSchemaV1Prices {
  default?: ProductSchemaV1PricesDefault;
  P?: ProductSchemaV1PricesP;
  T?: ProductSchemaV1PricesT;
  TOffline?: ProductSchemaV1PricesP;
}
export interface ProductSchemaV1AssetsInner {
  type?: ProductSchemaV1AssetsInnerTypeEnum;
  /** Link to asset */
  servletBaseUrl?: string;
  preview?: string;
  /** info about if asset is main picture or NB */
  position?: string;
  widthPixel?: string;
  heightPixel?: string;
  altText?: string;
  lastModifiedDate?: string;
  UID?: string;
}
export interface ProductSchemaV1AllergeneInfoIngredientsList {
  feName?: string;
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
export interface ProductSchemaV1AllergeneInfoAllergenesValuesInner {
  valueString?: string;
  occurance?: string;
}
export interface ProductSchemaV1AllergeneInfoAllergenes {
  feName?: string;
  values?: ProductSchemaV1AllergeneInfoAllergenesValuesInner[];
}
export interface ProductSchemaV1AllergeneInfoLabelling {
  values?: ProductSchemaV1ProvenanceStatementValuesInner[];
}
export interface ProductSchemaV1AllergeneInfoAlcohol {
  feName?: string;
  values?: ProductSchemaV1GuaranteedBestBeforeDateValuesInner[];
}
/** AllergeneInfo is a specific array and contains the fix defined attributes ingredientsList, allergenes, labelling, alcohol, content. Because of a different display logic these attributes are in a specific array */
export interface ProductSchemaV1AllergeneInfo {
  ingredientsList?: ProductSchemaV1AllergeneInfoIngredientsList;
  allergenes?: ProductSchemaV1AllergeneInfoAllergenes;
  labelling?: ProductSchemaV1AllergeneInfoLabelling;
  alcohol?: ProductSchemaV1AllergeneInfoAlcohol;
  content?: ProductSchemaV1AllergeneInfoIngredientsList;
}
export interface ProductSchemaV1NutritionServingSizeDescriptionValuesInner {
  valueString?: string;
  nutritionGroup?: number;
}
export interface ProductSchemaV1NutritionServingSizeDescription {
  feName?: string;
  values?: ProductSchemaV1NutritionServingSizeDescriptionValuesInner[];
}
export interface ProductSchemaV1NutritionNutritionTableValueNutrientBasisQuantityValuesInner {
  valueString?: string;
  amount?: number;
  unit?: string;
}
export interface ProductSchemaV1NutritionNutritionTableValueNutrientBasisQuantity {
  feName?: string;
  values?: ProductSchemaV1NutritionNutritionTableValueNutrientBasisQuantityValuesInner[];
}
/** In this example 201 is the nutrition group. The Clustering level according to nutrition group is optional and only exists if a product has mulitple nutrition groups. If a product does not have multiple nutrition groups, there is no grouping according to nutrition groups */
export interface ProductSchemaV1NutritionNutritionTableValue {
  nutrientBasisQuantity?: ProductSchemaV1NutritionNutritionTableValueNutrientBasisQuantity;
  energyKcal?: ProductSchemaV1AllergeneInfoAllergenes;
  fat?: ProductSchemaV1AllergeneInfoAllergenes;
  saturatedFat?: ProductSchemaV1AllergeneInfoAllergenes;
  carbohydrates?: ProductSchemaV1AllergeneInfoAllergenes;
  sugar?: ProductSchemaV1AllergeneInfoAllergenes;
  fiber?: ProductSchemaV1AllergeneInfoAllergenes;
  protein?: ProductSchemaV1AllergeneInfoAllergenes;
  salt?: ProductSchemaV1AllergeneInfoAllergenes;
  breadUnit?: ProductSchemaV1AllergeneInfoAllergenes;
  servingSize?: ProductSchemaV1AllergeneInfoIngredientsList;
}
/** contains fix set of attributes, all relevant for the nutrition table. All relevant attributes are Pimcore classificationStore Attributes */
export interface ProductSchemaV1Nutrition {
  servingSizeDescription?: ProductSchemaV1NutritionServingSizeDescription;
  preparationState?: ProductSchemaV1NutritionServingSizeDescription;
  /** in case of mixed food products like 1995534 nutrition attributes are clusterd according to their nutritions groups. The attributes of this array are fixed */
  nutritionTable?: Record<string, ProductSchemaV1NutritionNutritionTableValue>;
}
export interface ProductSchemaV1CategoriesInner {
  categoryType?: string;
  categoryId?: string;
  title?: string;
}
export interface ProductSchemaV1AvailabilityLinksSelf {
  /** link to availability mdsa call */
  href?: string;
}
export interface ProductSchemaV1AvailabilityLinks {
  self?: ProductSchemaV1AvailabilityLinksSelf;
}
/** Link to product availability information */
export interface ProductSchemaV1Availability {
  _links?: ProductSchemaV1AvailabilityLinks;
}
export interface ProductSchemaV1GtinListInner {
  code?: string;
  factor?: number;
}
export interface ProductSchemaV1BomsInner {
  bomType?: ProductSchemaV1BomsInnerBomTypeEnum;
  /** ProductId to which a releation exists */
  bomTarget?: string;
  bomFactor?: number;
  bomNumerator?: number;
}
export interface ProductSchemaV2BreadcrumbsInner {
  title?: string;
  categoryId?: string;
  level?: number;
  slug?: string;
  target?: string;
}
export interface ProductSchemaV2AssetsInner {
  type?: ProductSchemaV2AssetsInnerTypeEnum;
  /** Link to asset */
  servletBaseUrl?: string;
  preview?: string;
  damDocumentPath?: string;
  colorMode?: string;
  thumbnailImageLink?: string;
  progressiveUrl?: string;
  adaptiveUrl?: string;
  /** info about if asset is main picture or NB */
  position?: string;
  widthPixel?: string;
  heightPixel?: string;
  altText?: string;
  lastModifiedDate?: string;
  UID?: string;
}
/** contains fix set of attributes, all relevant for the nutrition table. All relevant attributes are Pimcore classificationStore Attributes */
export interface ProductSchemaV2Nutrition {
  /** in case of mixed food products like 1995534 nutrition attributes are clusterd according to their nutritions groups. The attributes of this array are fixed */
  nutritionTable?: Record<string, Record<string, ClassificationStoreAttribute>>;
  undefined?: undefined;
}
export interface ProductSchemaV2GtinListInner {
  code?: string;
  factor?: number;
}
export interface ProductSchemaV2BomsInner {
  bomType?: ProductSchemaV2BomsInnerBomTypeEnum;
  /** ProductId to which a releation exists */
  bomTarget?: string;
  bomFactor?: number;
  bomNumerator?: number;
  /** country of the related product */
  country?: string;
  /** name of the releated product */
  name1?: string;
  /** name of the releated product */
  name2?: string;
  /** name of the releated product */
  name3?: string;
  /** name of the releated product */
  name4?: string;
}
export interface ProductSchemaV2BomParentsInner {
  bomType?: ProductSchemaV2BomParentsInnerBomTypeEnum;
  /** ProductId to which a releation exists */
  bomSource?: string;
  bomFactor?: number;
  bomNumerator?: number;
  /** country of the related product */
  country?: string;
  /** name of the releated product */
  name1?: string;
  /** name of the releated product */
  name2?: string;
  /** name of the releated product */
  name3?: string;
  /** name of the releated product */
  name4?: string;
}
/** The sparProductType defines, which kind of product we are dealing with. Depending on certain attributes, or ERP Informations the sparProductType is set. Detailed description https://collaboration.spar.at/confluence/display/ES/SparProductType */
export const productSchemaV1SparProductTypeEnum = {
  Approximateweight: "ApproximateWeight",
  Display: "Display",
  General: "General",
  Gwk: "GWK",
  Partyservice: "PartyService",
  Procurement: "Procurement",
  Returnable: "Returnable",
  Service: "Service",
  Set: "Set",
  Shippingcosts: "ShippingCosts",
  Thm: "THM",
  Unsaleable: "Unsaleable",
  Voucher: "Voucher",
  Weighted: "Weighted",
} as const;
export type ProductSchemaV1SparProductTypeEnum =
  (typeof productSchemaV1SparProductTypeEnum)[keyof typeof productSchemaV1SparProductTypeEnum];
/** Sales unit describes in which unit an article is sold. For ex. certain meat is sold per g, TVs are sold per piece (eve) */
export const productSchemaV1SalesUnitEnum = {
  Eve: "eve",
  Gve: "gve",
  G: "g",
  Kg: "kg",
} as const;
export type ProductSchemaV1SalesUnitEnum =
  (typeof productSchemaV1SalesUnitEnum)[keyof typeof productSchemaV1SalesUnitEnum];
/** The sparProductType defines, which kind of product we are dealing with. Depending on certain attributes, or ERP Informations the sparProductType is set. Detailed description https://collaboration.spar.at/confluence/display/ES/SparProductType */
export const productSchemaV2SparProductTypeEnum = {
  Approximateweight: "ApproximateWeight",
  Display: "Display",
  General: "General",
  Gwk: "GWK",
  Partyservice: "PartyService",
  Procurement: "Procurement",
  Sellableprocurement: "SellableProcurement",
  Returnable: "Returnable",
  Service: "Service",
  Set: "Set",
  Shippingcosts: "ShippingCosts",
  Thm: "THM",
  Unsaleable: "Unsaleable",
  Voucher: "Voucher",
  Weighted: "Weighted",
} as const;
export type ProductSchemaV2SparProductTypeEnum =
  (typeof productSchemaV2SparProductTypeEnum)[keyof typeof productSchemaV2SparProductTypeEnum];
/** Sales unit describes in which unit an article is sold. For ex. certain meat is sold per g, TVs are sold per piece (eve) */
export const productSchemaV2SalesUnitEnum = {
  Eve: "eve",
  Gve: "gve",
  G: "g",
  Kg: "kg",
} as const;
export type ProductSchemaV2SalesUnitEnum =
  (typeof productSchemaV2SalesUnitEnum)[keyof typeof productSchemaV2SalesUnitEnum];
export const priceSchemaV1ProductCountryEnum = {
  De: "DE",
  At: "AT",
  Si: "SI",
  Hu: "HU",
  Hr: "HR",
  It: "IT",
  Ro: "RO",
} as const;
export type PriceSchemaV1ProductCountryEnum =
  (typeof priceSchemaV1ProductCountryEnum)[keyof typeof priceSchemaV1ProductCountryEnum];
export const priceSchemaV1ProductCurrencyEnum = {
  Eur: "EUR",
  Huf: "HUF",
} as const;
export type PriceSchemaV1ProductCurrencyEnum =
  (typeof priceSchemaV1ProductCurrencyEnum)[keyof typeof priceSchemaV1ProductCurrencyEnum];
export const productSchemaV1DeliveryModesInnerDeliveryModeIdEnum = {
  Atinstorepickupdropshipment: "AT-Instore-Pickup-Dropshipment",
  Atinstorepickupwarehouse: "AT-Instore-Pickup-Warehouse",
  Atparceldropshipment: "AT-Parcel-Dropshipment",
  Atparcelwarehouse: "AT-Parcel-Warehouse",
  Atspeditiondropshipment: "AT-Spedition-Dropshipment",
  Atspeditionwarehouse: "AT-Spedition-Warehouse",
  Attimeslotdelivery: "AT-Timeslot-Delivery",
  Attimeslotdrivein: "AT-Timeslot-DriveIn",
  Attimeslotpickupbox: "AT-Timeslot-PickupBox",
  Atvoucherdigital: "AT-Voucher-Digital",
  Atvoucherphysical: "AT-Voucher-Physical",
  Sitimeslotdelivery: "SI-Timeslot-Delivery",
  Sitimeslotdrivein: "SI-Timeslot-DriveIn",
  Sitimeslotpickupbox: "SI-Timeslot-PickupBox",
  Hutimeslotdelivery: "HU-Timeslot-Delivery",
  Hutimeslotdrivein: "HU-Timeslot-DriveIn",
  Hutimeslotpickupbox: "HU-Timeslot-PickupBox",
} as const;
export type ProductSchemaV1DeliveryModesInnerDeliveryModeIdEnum =
  (typeof productSchemaV1DeliveryModesInnerDeliveryModeIdEnum)[keyof typeof productSchemaV1DeliveryModesInnerDeliveryModeIdEnum];
export const productSchemaV1AssetsInnerTypeEnum = {
  Image: "image",
  Video: "video",
  Document: "document",
  Brandimage: "brandimage",
} as const;
export type ProductSchemaV1AssetsInnerTypeEnum =
  (typeof productSchemaV1AssetsInnerTypeEnum)[keyof typeof productSchemaV1AssetsInnerTypeEnum];
export const productSchemaV1BomsInnerBomTypeEnum = {
  Price: "Price",
  Procurement: "Procurement",
  Service: "Service",
  Deposit: "Deposit",
  Bundle: "Bundle",
  Display: "Display",
  Supplement: "Supplement",
  Replacement: "Replacement",
} as const;
export type ProductSchemaV1BomsInnerBomTypeEnum =
  (typeof productSchemaV1BomsInnerBomTypeEnum)[keyof typeof productSchemaV1BomsInnerBomTypeEnum];
export const productSchemaV2AssetsInnerTypeEnum = {
  Image: "image",
  Video: "video",
  Document: "document",
  Brandimage: "brandimage",
} as const;
export type ProductSchemaV2AssetsInnerTypeEnum =
  (typeof productSchemaV2AssetsInnerTypeEnum)[keyof typeof productSchemaV2AssetsInnerTypeEnum];
export const productSchemaV2BomsInnerBomTypeEnum = {
  Sellableprocurement: "Sellableprocurement",
  Procurement: "Procurement",
  Service: "Service",
  Deposit: "Deposit",
  Bundle: "Bundle",
  Display: "Display",
  Supplement: "Supplement",
  Replacement: "Replacement",
  Gtinmatch: "gtinMatch",
} as const;
export type ProductSchemaV2BomsInnerBomTypeEnum =
  (typeof productSchemaV2BomsInnerBomTypeEnum)[keyof typeof productSchemaV2BomsInnerBomTypeEnum];
export const productSchemaV2BomParentsInnerBomTypeEnum = {
  Sellableprocurement: "Sellableprocurement",
  Procurement: "Procurement",
  Service: "Service",
  Deposit: "Deposit",
  Bundle: "Bundle",
  Display: "Display",
  Supplement: "Supplement",
  Replacement: "Replacement",
  Gtinmatch: "gtinMatch",
} as const;
export type ProductSchemaV2BomParentsInnerBomTypeEnum =
  (typeof productSchemaV2BomParentsInnerBomTypeEnum)[keyof typeof productSchemaV2BomParentsInnerBomTypeEnum];
