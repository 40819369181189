export const PaymentStatus = {
  Error: "error",
  Success: "success",
  Cancel: "cancel",
} as const;

type PaymentStatusType = (typeof PaymentStatus)[keyof typeof PaymentStatus];

export interface PayPalQueryParams {
  paymentStatus: PaymentStatusType;
  token: string;
  payerId: string;
  ba_token: string;
  cartId: string;
  baseSiteId: string;
}

export interface PayOneQueryParams {
  paymentStatus: PaymentStatusType;
  cartId: string;
  baseSiteId: string;
  REF: string;
  RETURNMAC: string;
  paymentId: string;
}
