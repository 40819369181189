import type { PaymentDetailsSPAR } from "~/types/occ-custom.types";

interface PaymentMode {
  code: string;
  description: string;
  icon: PaymentModeIcon;
  name: string;
  hasError?: boolean;
}

export interface PaymentModes {
  paymentModes: PaymentMode[];
}

interface PaymentModeIcon {
  url: string;
}

export const PaymentStatus = {
  loading: "loading", // initial load
  initialized: "initialized",
  ready: "ready",
  busy: "busy", // ongoing transaction
  error: "error",
} as const;
export type PaymentStatus = (typeof PaymentStatus)[keyof typeof PaymentStatus];

export const PaymentProvider = {
  paymentDummy: "payment_dummy", // TODO: Remove before Go-Live
  payOne: "payOne",
  payPalAt: "paypal-at",
  payPalHu: "paypal-hu",
  payPalSi: "paypal-si",
} as const;
export type PaymentProvider = (typeof PaymentProvider)[keyof typeof PaymentProvider];

export interface InitPaymentProviderResponse {
  initPaymentProviderResponse: InitPaymentProviderResponseObj;
  paymentDetails?: InitPaymentProviderPaymentDetails;
}

interface InitPaymentProviderResponseObj {
  paymentProviderSessionToken: string;
  paymentProviderUrl: string;
  validPaymentDetailsToken?: string[];
}

interface InitPaymentProviderPaymentDetails {
  payments: PaymentProviderPayment[];
}

export interface PaymentProviderPayment extends PaymentDetailsSPAR {
  savedCard?: boolean; // saved on backend
  addedCard?: boolean; // added by user in this session
}

export interface PayOneTokenizer {
  initialize: () => Promise<unknown>;
  submitTokenization: () => Promise<PayOneTokenizerSubmitResponse>;
}

interface PayOneTokenizerSubmitResponse {
  error: PayOneTokenizerSubmitResponseError | undefined;
  hostedTokenizationId: string | undefined;
  success: boolean;
}

interface PayOneTokenizerSubmitResponseError {
  message: string;
}
