export const RobotIndex = {
  all: "all",
  noindex: "noindex",
} as const;
export type RobotIndex = (typeof RobotIndex)[keyof typeof RobotIndex];

export const RobotFollow = {
  follow: "follow",
  nofollow: "nofollow",
} as const;
export type RobotFollow = (typeof RobotFollow)[keyof typeof RobotFollow];
