<template>
  <div class="brands-details container" :class="{ 'local-producer': content?.[0]?.localProducer }">
    <template v-if="content?.[0]?.topSections?.length">
      <section class="brands-section">
        <render-sections :content="content[0].topSections" />
      </section>
    </template>

    <div class="brands-section container">Placeholder Breadcrumbs</div>
    <div class="brands-details__columns">
      <div class="brands-details__column--left brands-section container">Placeholder Sidenav</div>
      <div class="brands-details__column--right">
        <div class="brands-section brands-details__details-container">
          <spar-heading
            v-if="!content?.[0]?.localProducer && content?.[0]?.brandName"
            class="brand-details__name heading--left container"
            level="1"
            headline-style="1"
            :title="content[0].brandName"
          />
          <div v-if="content?.[0]?.localProducer" class="brands-details__local-producer container">
            <div class="local-producer__details-container">
              <spar-heading
                v-if="content?.[0]?.brandName"
                class="brand-details__name heading--left"
                level="1"
                headline-style="1"
                :title="content[0].brandName"
                :description="content?.[0]?.localProducerInfo?.city"
              />
              <div
                v-if="content?.[0]?.localProducerInfo?.assortments"
                class="local-producer__assortments"
              >
                <div
                  v-for="(assortment, index) in content[0].localProducerInfo.assortments"
                  :key="index"
                >
                  <div class="local-producer__assortment">
                    <spar-icon-sprite
                      class="local-producer__assortment-icon"
                      :symbol="assortment"
                    />
                    {{ $t(`brands.local_producer.assortment.${assortment}.title`) }}
                  </div>
                </div>
              </div>
              <div class="local-producer__details">
                <div
                  v-if="content?.[0]?.localProducerInfo?.address"
                  class="local-producer__address"
                >
                  <span class="f-bold">{{ $t("brands.local_producer.address.title") }}&nbsp;</span
                  >{{ content[0].localProducerInfo.address }}
                </div>
                <div v-if="content?.[0]?.localProducerInfo?.sector" class="local-producer__sector">
                  <span class="f-bold">{{ $t("brands.local_producer.sector.title") }}&nbsp;</span
                  >{{ content[0].localProducerInfo?.sector }}
                </div>
                <div
                  v-if="content?.[0]?.localProducerInfo?.foundingDate"
                  class="local-producer__founding-date"
                >
                  <span class="f-bold"
                    >{{ $t("brands.local_producer.founding_date.title") }}&nbsp;</span
                  >{{ content[0].localProducerInfo.foundingDate }}
                </div>
                <div
                  v-if="content?.[0]?.localProducerInfo?.products"
                  class="local-producer__products"
                >
                  <span class="f-bold">{{ $t("brands.local_producer.products.title") }}&nbsp;</span
                  >{{ content[0].localProducerInfo.products }}
                </div>
              </div>
            </div>
            <div
              v-if="content?.[0]?.localProducerInfo?.image"
              class="local-producer__image-container"
            >
              <spar-adaptive-image
                class="local-producer__image"
                :image="content[0].localProducerInfo.image"
              />
            </div>
          </div>

          <template v-if="content?.[0]?.middleSections?.length">
            <section class="brands-section brands-details__content-container">
              <render-sections :content="content[0].middleSections" />
            </section>
          </template>
        </div>
        <div
          v-if="
            searchResults?.products?.resolvedProducts &&
            searchResults.products.resolvedProducts.length
          "
          class="brand-details__products brands-section container"
        >
          <spar-product-tile
            v-for="(product, index) in searchResults.products.resolvedProducts"
            :key="index"
            :product-id="product.productId"
            :name1="product.name1"
            :name2="product.name2"
            :name3="product.name3"
            :name4="product.name4"
            :tile-picture="product.tilePicture"
            :tile-sales-price="product.tileSalesPrice"
          ></spar-product-tile>
        </div>
        <div class="brands-section container">Placeholder Stores</div>

        <template v-if="content?.[0]?.bottomSections?.length">
          <section class="brands-section">
            <render-sections :content="content[0].bottomSections" />
          </section>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BrandsDetailsPage } from "@shop-storefront/utils";
import queryString from "query-string";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import SparAdaptiveImage from "~/components/shared/SparAdaptiveImage/SparAdaptiveImage.vue";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import SparProductTile from "~/components/shared/SparProductTile/SparProductTile.vue";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import { useSearchStore } from "~/stores/search.store";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { brandsDetailsPageReferences } from "~/utils/contentstack/constants/references.constants";
import { resolvePageSeo } from "~/utils/contentstack/resolvers/gloabal-fields/page-seo/page-seo.resolvers";

const { $contentstack } = useNuxtApp();
const {
  params: { brand },
} = useRoute();

const searchStore = useSearchStore();
const { searchResults } = storeToRefs(useSearchStore());

// Load Contentstack Sections for SSR
const { data: content } = await useAsyncData(ContentType.brandsDetailsPage, async () => {
  return await $contentstack.getEntries<BrandsDetailsPage>({
    type: ContentType.brandsDetailsPage,
    customQuery: {
      [ContentstackPageParams.slug]: brand,
    },
    includeReference: brandsDetailsPageReferences as string[],
  });
});

if (content?.value?.[0]?.seo) {
  useHead(resolvePageSeo(content.value[0].seo));
}

searchStore.setQuery(
  queryString.parse(`name1=${brand}`, {
    parseNumbers: true,
    arrayFormat: "comma",
  }),
);
</script>

<style lang="scss">
@use "./SparBrandsDetails.scss";
</style>
