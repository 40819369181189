<template>
  <div class="buy-box-big">
    <div class="buy-box-big__context">
      {{ $t(`plp.product.tile.availability.${shopContext}`) }}
      <spar-icon-sprite symbol="in" />
    </div>

    <!-- <div
      v-if="buyBox.prices?.promoText"
      class="buy-box-big__promo-badge"
      data-tosca="pdp-big-promo-badge"
    >
      {{ buyBox.prices?.promoText }}
    </div> -->

    <div v-if="productPrice?.length" class="buy-box-big__price-wrapper">
      <!-- <div
        v-if="buyBox.prices?.recommendedRetailPriceString"
        class="buy-box-big__uvp"
        data-tosca="pdp-uvp-price"
      >
        {{ buyBox.prices.recommendedRetailPriceString }}
      </div> -->
      <!-- <div class="buy-box-big__savings">
        <div
          v-if="buyBox.prices?.savingsString"
          class="buy-box-big__savings-string"
          data-tosca="pdp-price-savings"
        >
          {{ buyBox.prices?.savingsString }}
        </div>
      </div> -->
      <!-- <div
        v-if="buyBox.prices?.insteadPriceString"
        class="buy-box-big__instead-price"
        data-tosca="pdp-instead-price"
      >
        {{ buyBox.prices.insteadPriceString }}
      </div> -->
      <div class="buy-box-big__price-detail">
        <div
          v-if="productPrice[0].salesPrice.price"
          class="buy-box-big__price"
          data-tosca="pdp-price"
        >
          {{ formatPrice(productPrice[0].salesPrice.price) }}
        </div>
        <!-- <div
          v-if="buyBox.prices.referencePriceString"
          class="buy-box-big__reference-price"
          data-tosca="pdp-reference-price"
        >
          {{ buyBox.prices.referencePriceString }}
        </div> -->
      </div>
      <div v-if="productPrice?.length" class="buy-box-big__price-info">
        <div v-if="productPrice[0].taxRate">
          {{
            $t("pdp.product.plus_tax_costs.label", {
              taxValue: numberFormat(productPrice[0].taxRate.toFixed(1)),
            })
          }}
        </div>
        <spar-link
          :variant="LinkVariant.secondary"
          :link="$t('pdp.product.plus_delivery_costs.link')"
          target="_blank"
          :title="$t('pdp.product.plus_delivery_costs.label')"
          tosca-prefix="pdp-delivery-costs"
          >{{ $t("pdp.product.plus_delivery_costs.label") }}</spar-link
        >
      </div>
      <!-- <div class="buy-box-big__tax-info">
        <div v-if="buyBox.prices?.priceValidUntilString" class="buy-box-big__tax-text">
          {{ buyBox.prices.priceValidUntilString }}
        </div>
      </div> -->

      <!-- <ul v-if="documents.length" class="buy-box-big__documents">
        <li v-for="(document, index) in documents" :key="index">
          <spar-link
            :link="document.servletBaseUrl"
            :label="getDocumentLabel(document)"
            target="_blank"
            tosca-prefix="pdp-datasheet"
          />
        </li>
      </ul> -->

      <!-- <spar-alert
        v-if="
          mainInfo.excludeDeliveryAbroad &&
          buyBox.priceType !== DeliveryModeVoucher.VoucherDigital &&
          buyBox.priceType !== PriceType.T
        "
        :type="SparAlertType.info"
        :is-closable="false"
        :tosca-prefix="`buy-box-delivery-abroad-not-possible`"
        class="buy-box-big__delivery-alert"
      >
        {{ $t("plp.product.tile.excludeDeliveryAbroad.label") }}
      </spar-alert> -->

      <!-- <div v-if="additionalServices.length">
        <spar-additional-service
          v-for="(service, index) in additionalServices"
          :key="index"
          v-model="selectedServices"
          :service="service"
          :service-id="service.mainInfo.productId"
          :title="service.mainInfo.name1"
          :description="service.productDescription?.productMaintenanceText?.values[0].value"
          :price-string="service.prices.P?.prices?.salesPriceString"
          tosca-prefix="pdp"
        />
      </div> -->
    </div>

    <spar-availability-product
      :product="locationOverlayProduct"
      :is-available="
        outletAvailability && outletAvailability.availability === OutletAvailability.AVAILABLE
      "
    />

    <div class="pdp-product__ecom-availability" data-tosca="pdp-availability">
      <spar-icon-sprite v-bind="iconProps" />

      <span>{{ $t(`plp.product.tile.availability.${isBuyable ? "buyable" : "searchable"}`) }}</span>
    </div>

    <!--   <div
      class="buy-box-big__cart-actions"
      :class="{
        'buy-box-big__cart-actions-alert':
          mainInfo.excludeDeliveryAbroad &&
          buyBox.priceType !== DeliveryModeVoucher.VoucherDigital &&
          buyBox.priceType !== PriceType.T,
      }"
    > -->
    <!--     <spar-button
          data-tosca="pdp-favorite"
          class="pdp-product__wishlist"
          :variant="ButtonVariant.custom"
          :title="$t('wishlist.add_item')"
          :aria-label="$t('wishlist.add_item')"
          @click="toggleWishlist"
        >
          <spar-icon-sprite
            :class="{ 'pdp-product__wishlist--heart-filled': wishlistSymbol === 'heart-filled' }"
            :symbol="wishlistSymbol"
          />
        </spar-button>
      -->
    <spar-quantity-button
      tosca-prefix="pdp"
      class="buy-box-big__add-to-cart-btn"
      :product="product"
      :buyable="isBuyable"
      :boundaries="{
        min: product[`minOrderQuantity${shopContext}`] ?? 0,
        max: product[`maxOrderQuantity${shopContext}`] ?? 0,
      }"
      :shop-context="shopContext"
      layout="big"
    />

    <!--    <div
        v-if="
          availability.eComAvailability.currentlyReservable &&
          buyBox.priceType === AvailabilityPossibility.P
        "
        class="buy-box-big__reservable"
      >
        <spar-button
          data-tosca="pdp-reserve-in-market"
          :label="$t('pdp.product.reserve_in_market')"
          class="buy-box-big__reservable-btn btn--secondary"
          @click="reserveInMarket"
          >{{ $t("pdp.product.reserve_in_market") }}</spar-button
        >
      </div>
    </div>

    <div v-if="buyMessage" class="buy-box-big__availability-text" data-tosca="pdp-availability">
      <div class="buy-box-big__availability-text1">
        <spar-icon-sprite :class="getIconStyling(props.buyMessage || '')" symbol="check-rounded" />
        {{ $t(`plp.product.tile.availability.${buyMessage}`) }}
      </div>
      <div v-if="mainInfo?.deliveryTimeException" class="buy-box-big__availability-deliverytime">
        {{ mainInfo.deliveryTimeException }}
      </div>
      <span v-else class="buy-box-big__availability-deliverytime">{{ getDeliveryModeTime() }}</span>
    </div> -->
  </div>
</template>

<script lang="ts" setup>
import type { LocationOverlayProduct } from "~/components/feature/SparLocation/SparLocationOverlay/SparLocationOverlay.types";
import SparAvailabilityProduct from "~/components/shared/SparAvailabilityProduct/SparAvailabilityProduct.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import { LinkVariant } from "~/components/shared/SparLink/SparLink.types";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import type { PriceDetails } from "~/types/ppc.types";
import {
  type AvailabilityType,
  type EcomAvailabilityTypeItem,
  type OutletAvailabilitySchema,
  EcomAvailability,
  OutletAvailability,
} from "~/utils/availability/integration/availability.types";
import {
  getFallbackImage,
  getFallbackImageAlt,
} from "~/utils/contentstack/resolvers/product/product.utils";
import type { SparMdsaProduct } from "~/utils/mdsa/integration/mdsa.types";
import { formatPrice, numberFormat } from "~/utils/ui";
import SparQuantityButton from "../../SparQuantityButton/SparQuantityButton.vue";
import { BuyBoxFormat } from "../SparBuyBox.types";
import type { SparBuyBoxBigProps } from "./SparBuyBoxBig.types";

const props: SparBuyBoxBigProps = defineProps({
  ecomAvailability: {
    type: Object as PropType<EcomAvailabilityTypeItem>,
    default: () => {},
  },
  outletAvailability: {
    type: Object as PropType<OutletAvailabilitySchema>,
    default: () => {},
  },
  product: {
    type: Object as PropType<SparMdsaProduct>,
    required: true,
  },
  productPrice: {
    type: Array as PropType<PriceDetails[]>,
    default: () => [],
  },
  boxFormat: {
    type: String as PropType<BuyBoxFormat>,
    default: BuyBoxFormat.small,
  },
  shopContext: {
    type: String as PropType<AvailabilityType>,
    default: "",
  },
  // additionalServices: {
  //   type: Array as PropType<SparProduct[]>,
  //   default: () => [],
  // },
  // availability: {
  //   type: Object as PropType<ProductAvailability>,
  //   default: null,
  // },
  // buyBox: {
  //   type: Object as PropType<ProductPriceItem>,
  //   required: true,
  // },
  // buyMessage: {
  //   type: String,
  //   default: "",
  // },
  // buyable: {
  //   type: Boolean,
  //   default: true,
  // },
  // documents: {
  //   type: Array as PropType<ProductPicture[]>,
  //   default: () => [],
  // },
});

// const { getDocumentLabel } = usePdpProduct();

const { $t } = useI18n();

const iconProps = computed(() => {
  if (isBuyable.value) {
    return {
      class: "pdp-product__availability-icon--green",
      symbol: "available",
    };
  }

  return {
    class: "pdp-product__availability-icon--red",
    symbol: "not-available",
  };
});

const isBuyable = computed(
  () => props.ecomAvailability && props.ecomAvailability.value === EcomAvailability.buyable,
);

const locationOverlayProduct = computed(() => {
  const imgSrc = props.product.assets?.[0]?.servletBaseUrl || getFallbackImage();
  const imgAlt = props.product.assets?.[0]
    ? props.product.assets?.[0].altText || getFallbackImageAlt(props.product)
    : $t("pdp.product.image.alt_fallback");

  const res: LocationOverlayProduct = {
    productId: props.product.productId,
    name1: props.product.name1,
    name2: props.product.name2,
    imgSrc,
    imgAlt,
  };

  return res;
});

// const selectedServices = ref([]);

// const getDeliveryModeTime = () => {
//   const deliveryModeMessage = props.mainInfo.deliveryModes?.filter((deliveryMode) => {
//     if (
//       props.sparProductType === SparProductType.Voucher ||
//       props.sparProductType === SparProductType.GWK
//     ) {
//       return props.buyBox.priceType === deliveryMode.deliveryModeId;
//     }

//     return props.buyBox.priceType === deliveryMode.associatedAvailabilityType;
//   });

//   if (deliveryModeMessage?.length) {
//     return `${deliveryModeMessage[0].defaultDeliveryTime}`;
//   }
// };

// const reserveInMarket = () => {
//   // TODO do whatever reserveInMarket should do
//   Log.log(LogArea.buyBox, "add to market");
// };

// const isWishlist = ref(false);
// const wishlistSymbol = ref("heart");
// const toggleWishlist = () => {
//   isWishlist.value = !isWishlist.value;
//   wishlistSymbol.value = isWishlist.value ? "heart-filled" : "heart";
// };
</script>

<style lang="scss">
@use "./SparBuyBoxBig.scss";
</style>
