import { defineStore } from "pinia";
import type { PricesSchema } from "~/types/ppc.types";
import { PPCError } from "~/utils/error";

export const usePPCStore = defineStore("ppc", () => {
  const productPrice = ref<PricesSchema>();
  const runtimeConfig = useRuntimeConfig();

  const { $ppc } = useNuxtApp();

  /**
   * IMPORTANT - Please note, we do not add a key (useFetch unique key) here because we need a CSR reload on the PDP for this function.
   */
  const loadProductPrice = async (productId: string | string[]) => {
    const dateNow = useDateFormat(useNow(), "YYYY-MM-DD", { locales: "de-DE" });
    try {
      const res = await $ppc.getPrices({
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: {
          countryCode: "AT",
          calculatedForDate: dateNow.value,
          outletNumber: runtimeConfig.public.storeIdMarketPrice,
          productIds: productId,
        },
      });

      if (res.data.value) {
        productPrice.value = res.data.value as PricesSchema;
      }
    } catch (error) {
      throw new PPCError("Product ppc error");
    }
  };

  return {
    loadProductPrice,
    productPrice,
  };
});
