import { defineStore } from "pinia";
import type { BaseSitesResult, SparBaseSite } from "~/composables/base-sites/baseSites.types";
import { getMiddlwareUrl } from "~/utils/ui";

export const useBaseSiteStore = defineStore("base-site", () => {
  const runtimeConfig = useRuntimeConfig();
  const sapBaseSites = ref<SparBaseSite[] | null>(null);

  /**
   * Get all available baseSites of the country and store them
   *
   */
  async function setBaseSites(): Promise<void> {
    if (sapBaseSites.value) return;

    const { data, error } = (await useFetch(
      () => `${getMiddlwareUrl(runtimeConfig)}/sapcc/baseSites`,
      {
        method: "post",
        credentials: "include",
      },
    )) as BaseSitesResult;

    if (error.value || !data.value?.baseSites) return;

    sapBaseSites.value = data.value.baseSites
      .filter((baseSite: SparBaseSite) => {
        return baseSite.uid?.includes(`${runtimeConfig.public.sapccApiTopLevelDomain}-`);
      })
      .map((baseSite: SparBaseSite) => {
        return {
          ...baseSite,
          resolvedUid: baseSite.uid?.split("-")[1],
        };
      });
  }

  /**
   * Checks if a base site is active.
   */
  function isBaseSiteActive(baseSite: string) {
    if (!sapBaseSites.value) return false;

    const res = sapBaseSites.value.find((sapBaseSite: SparBaseSite) => {
      return sapBaseSite.uid?.includes(baseSite);
    }) as SparBaseSite;

    return res?.active;
  }

  /**
   * Returns axios request header for base site id
   */
  function baseSiteConfig(baseSite: string) {
    return {
      axiosRequestConfig: {
        headers: {
          "x-base-site-id": baseSite,
        },
      },
    };
  }

  return {
    sapBaseSites,
    setBaseSites,
    baseSiteConfig,
    isBaseSiteActive,
  };
});
